<template>
  <v-dialog v-model="dialogShown"
            scrollable
            content-class="schedule-booking-detail"
            max-width="480px">
    <v-card class="text-left"
            v-if="booking">
      <v-card-title :class="['schedule-booking-detail-title', booking.status]">{{
          booking.item.type.name
        }}
        <v-spacer></v-spacer>
        <v-btn icon
               dark
               v-if="!hideEdit && booking.account.id === account.id"
               @click="editBooking">
          <v-icon>mdi-pencil-outline</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-list dense>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <h3>{{ booking.title }}</h3>
              </v-list-item-title>
              <v-list-item-subtitle>
                <div>{{ $parseDate(booking.start_time) }} - {{
                    $parseDate(booking.end_time, 'HH:mm')
                  }}
                </div>
                <div v-if="booking.bookingRecord && booking.bookingRecord.recurrence">
                  {{
                    $parseCronString(booking.bookingRecord.recurrence, booking.bookingRecord.recur_until)
                  }}
                </div>
                <div>Booked On {{ $parseDate(booking.created_at) }}</div>
                <div v-if="booking.approved_by">Approved By {{ booking.approved_by.name }} On {{ $parseDate(booking.approved_at) }}</div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-information-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t(`booking_status.${booking.status}`) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-for="(host, index) in booking.hosts">
            <v-list-item-icon>
              <v-icon>{{ index === 0 ? 'mdi-account-edit' : null }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ host.name }}
                <small v-if="host.id === booking.account.id"
                       class="text--disabled">(Creator)</small>
              </v-list-item-title>
              <v-list-item-subtitle v-if="host.title">
                {{ host.title }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="host.mobile">
                {{ host.mobile }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="host.email">
                {{ host.email }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-map-marker-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ booking.item.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!--Invitations-->
          <v-list-item v-if="bookingInvitations.length">
            <v-list-item-icon>
              <v-icon>mdi-account-multiple-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ bookingInvitations.length }} {{ bookingInvitations.length > 1 ? $t('invitation.counts') : $t('invitation.count') }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ bookingInvitationDescriptions }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-for="invitation in bookingInvitations"
                       class="pl-8">
            <v-list-item-icon class="ml-2"
                              :key="invitation.id+invitationIcon(invitation)">
              <v-icon>{{ invitationIcon(invitation) }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ invitation.account.name }}
                <small v-if="invitation.role === INVITATION_ROLE.CHAIR"
                       class="text--disabled">(Chair)</small></v-list-item-title>
              <!--                <v-list-item-subtitle v-text="invitation.account.username"></v-list-item-subtitle>-->
              <v-list-item-subtitle v-if="invitation.account.title">
                {{ invitation.account.title }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="invitation.account.mobile">
                {{ invitation.account.mobile }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="invitation.account.email">
                {{ invitation.account.email }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <!--Additional Items-->
          <v-list-item v-for="additionalItem in bookingAdditionalItems">
            <v-list-item-icon>

            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle>{{ additionalItem.item_type_additional_item.name }}</v-list-item-subtitle>
              <v-list-item-title>{{ additionalItem.value }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions v-if="showRsvpActions && myInvitation">
        Attend?
        <v-spacer></v-spacer>
        <v-btn text
               :color="myInvitation.status === INVITATION_STATUS.ACCEPTED ? 'primary' : null"
               @click="rsvp(INVITATION_STATUS.ACCEPTED)">
          Yes
        </v-btn>
        <v-btn text
               :color="myInvitation.status === INVITATION_STATUS.DECLINED ? 'primary' : null"
               @click="rsvp(INVITATION_STATUS.DECLINED)">
          No
        </v-btn>
        <v-btn text
               :color="myInvitation.status === INVITATION_STATUS.TENTATIVE ? 'primary' : null"
               @click="rsvp(INVITATION_STATUS.TENTATIVE)">
          Maybe
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {computed, ref, watch} from "@vue/composition-api";
import _ from "lodash";
import {INVITATION_ROLE, INVITATION_STATUS} from "@/constants";
import EditRecurringDialog from "@/components/EditRecurringDialog";
import {ACTION_TYPES} from "@/store/types";

export default {
  name: 'BookingDetailModal',
  props: {
    booking: Object,
    shown: Boolean,
    showRsvpActions: Boolean,
    hideEdit: Boolean,
  },
  setup(props, {root, emit}) {
    const dialogShown = ref(false);

    watch(() => props.shown, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        dialogShown.value = newValue;
      }
    });

    watch(() => dialogShown.value, (newValue) => {
      if (!newValue) {
        emit('dismiss')
      }
    })

    const account = computed(() => {
      return root.$store.getters.account;
    });

    const editBooking = function () {
      root.$router.push({name: 'booking-details', params: {bookingId: props.booking.id}});
    };

    const getRoleIdx = function (i) {
      return {
        [INVITATION_ROLE.CHAIR]: 1,
        [INVITATION_ROLE.REQUIRED_PARTICIPANT]: 2,
        [INVITATION_ROLE.OPTIONAL_PARTICIPANT]: 3,
        [INVITATION_ROLE.NON_PARTICIPANT]: 4,
      }[i.role] || 5;
    };
    const bookingInvitations = computed(() => {
      if (!props.booking) return [];

      return _.sortBy(
          _.filter(props.booking.invitations, (i) => i.status !== INVITATION_STATUS.CANCELLED),
          [getRoleIdx, (i) => {
            return {
              [INVITATION_STATUS.ACCEPTED]: 0,
              [INVITATION_STATUS.TENTATIVE]: 1,
              [INVITATION_STATUS.DECLINED]: 2,
              [INVITATION_STATUS.NEEDS_ACTION]: 3,
            }[i.status]
          }, 'name']);
    })
    const bookingInvitationDescriptions = computed(() => {
      let counts = _.countBy(_.filter(bookingInvitations.value, (i) => i.status !== INVITATION_STATUS.NEEDS_ACTION), 'status');

      return _.keys(counts).map((status) => {
        return `${counts[status]} ${{
          [INVITATION_STATUS.NEEDS_ACTION]: ' awaiting',
          [INVITATION_STATUS.ACCEPTED]: ' yes',
          [INVITATION_STATUS.DECLINED]: ' no',
          [INVITATION_STATUS.TENTATIVE]: ' maybe',
        }[status]}`;
      }).join(', ');
    })
    const invitationIcon = function (invitation) {
      return {
        [INVITATION_ROLE.CHAIR]: 'mdi-chair-rolling',
        [INVITATION_ROLE.REQUIRED_PARTICIPANT]: 'mdi-account-star',
        [INVITATION_ROLE.OPTIONAL_PARTICIPANT]: 'mdi-account-question',
        [INVITATION_ROLE.NON_PARTICIPANT]: 'mdi-account-remove',
      }[invitation.role];
      // return {
      //   [INVITATION_STATUS.NEEDS_ACTION]: 'fas fa-clock',
      //   [INVITATION_STATUS.ACCEPTED]: 'fas fa-check',
      //   [INVITATION_STATUS.DECLINED]: 'fas fa-times',
      //   [INVITATION_STATUS.TENTATIVE]: 'fas fa-question',
      // }[invitation.status];
    };
    const myInvitation = computed(() => {
      if (!props.booking || !account.value) return false;

      return _.head(_.filter(props.booking.invitations, (i) => i.account.id === account.value.id));
    });
    const rsvp = async function (status) {
      let edit = 'this';

      if (props.booking.bookingRecord.recurrence) {
        edit = await root.$dialog.showAndWait(EditRecurringDialog, {title: 'RSVP recurring booking'});
        if (edit === undefined || edit === false) return;
      }

      await root.$store.dispatch(ACTION_TYPES.RSVP_INVITATION, {
        id: myInvitation.value.id,
        response: status,
        edit,
      })

      await root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, root.$t('invitation.rsvp_success_msg', {
        status: root.$t(`invitation_status.${status}`),
        title: props.booking.title
      }));
    };


    const bookingAdditionalItems = computed(() => {
      if (props.booking) {
        return _.sortBy(props.booking.additional_items, (i) => i.item_type_additional_item.ordering);
      }

      return [];
    })

    return {
      dialogShown,
      account,
      editBooking,
      bookingInvitations,
      bookingInvitationDescriptions,
      invitationIcon,
      myInvitation,
      rsvp,

      bookingAdditionalItems,

      INVITATION_ROLE,
      INVITATION_STATUS,
    }
  },
}
</script>
