<template>
  <v-container fluid class="schedule-container">
    <v-row no-gutters
           class="flex-grow-0 align-center">
      <v-btn tile text small
             @click="today">Today
      </v-btn>
      <v-btn tile text small
             @click="prev">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn tile text small
             @click="next">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      {{ calendarDate }}
      <v-spacer></v-spacer>
      <v-btn-toggle tile
                    dense
                    group
                    mandatory
                    color="primary"
                    v-model="calendarType">
        <v-btn :value="'day'">
          D
        </v-btn>
        <v-btn :value="'4day'">
          4
        </v-btn>
        <v-btn :value="'month'">
          M
        </v-btn>
      </v-btn-toggle>
    </v-row>
    <v-sheet height="calc(100vh - 142px)"
             :class="['flex-grow-1', {'app-bar-shown-md-and-up': appBarShown && $vuetify.breakpoint.mdAndUp}, {'app-bar-shown-sm-and-down': appBarShown && $vuetify.breakpoint.smAndDown}]">
      <v-calendar ref="calendar"
                  :type="calendarType"
                  :weekdays="[1,2,3,4,5,6,0]"
                  :start="calendarStart"
                  :end="calendarEnd"
                  :events="events"
                  :event-color="getEventColor"
                  v-model="selectedDate"
                  @click:more="calendarClickDate"
                  @click:date="calendarClickDate"
                  event-overlap-mode="stack">
        <template v-slot:event="{event}">
          <v-row no-gutters
                 :class="['text-left', 'font-weight-bold', 'fill-height', 'py-0', 'pl-1']"
                 @click="onEventClick($event, event)">
            <v-col cols="1"
                   class="d-flex align-center justify-center"
                   v-if="event.my_invitation">
              <div :class="['rsvp_bubble', `RSVP_${event.my_invitation.status}`]"></div>
            </v-col>
            <v-col :cols="event.my_invitation ? 11 : 12"
                   :class="['d-flex', 'align-center', 'rounded', event.status, event.timed === false ? 'HOLIDAY': '']">
              <v-img :src="$getURL(event.item.type.icon)"
                     height="20"
                     width="20"
                     contain
                     class="item-type-icon"
                     v-if="event.timed !== false"></v-img>
              {{ eventLabel(event) }}
            </v-col>
          </v-row>
        </template>
      </v-calendar>
    </v-sheet>

    <!--BOOKING DETAIL-->
    <BookingDetailModal :shown="bookingDetailModalShown"
                        @dismiss="bookingDetailModalShown = false"
                        :booking="showingBooking"
                        show-rsvp-actions></BookingDetailModal>

    <!--FILTER-->
    <v-navigation-drawer color="#F4F5F7"
                         v-model="filterShown"
                         app
                         right>
      <v-container class="filter-container text-left">
        <v-row class="flex-grow-0 text-center">
          <v-col>FILTER</v-col>
        </v-row>
        <v-form ref="filterForm">
          <v-date-picker :value="selectedDateString"
                         @input="onDateSelected"
                         hide-details="auto"
                         dense
                         no-title
                         scrollable
                         full-width></v-date-picker>

          <v-select label="Facility Type"
                    v-model="selectedFacilityTypes"
                    :items="facilityTypeOptions"
                    :return-object="false"
                    multiple
                    chips
                    deletable-chips
                    class="flex-grow-0"></v-select>
        </v-form>
        <v-checkbox label="With Cancelled Bookings"
                    v-model="showCancelledBookings"></v-checkbox>
        <!--        <v-select label="My Role"-->
        <!--                  v-model="roles"-->
        <!--                  :items="roleOptions"-->
        <!--                  :return-object="false"-->
        <!--                  multiple-->
        <!--                  chips-->
        <!--                  deletable-chips-->
        <!--                  class="flex-grow-0"></v-select>-->
        <v-spacer></v-spacer>
        <!--        <v-row class="flex-grow-0 font-weight-bold text-center">-->
        <!--          <v-col>LEGENDS</v-col>-->
        <!--        </v-row>-->
        <v-list dense>
          <v-subheader>Facility</v-subheader>
          <v-list-item v-for="itemType in itemTypes">
            <v-list-item-icon>
              <v-img :src="$getURL(itemType.icon)"
                     height="24px"
                     width="24px"
                     contain></v-img>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle>{{ itemType.name }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list dense>
          <v-subheader>Booking Status</v-subheader>
          <v-list-item v-for="status in bookingStatus">
            <v-list-item-icon>
              <v-avatar
                  :color="status"
                  rounded
                  size="20"></v-avatar>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle>{{ $t(`booking_status.${status}`) }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list dense>
          <v-subheader>Holiday</v-subheader>
          <v-list-item>
            <v-list-item-icon>
              <v-avatar
                  color="HOLIDAY"
                  rounded
                  size="20"></v-avatar>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle>Public Holiday</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list dense>
          <v-subheader>RSVP</v-subheader>
          <v-list-item v-for="status in invitationStatus">
            <v-list-item-icon class="align-center justify-center">
              <div :class="['rsvp_bubble', 'legend', `RSVP_${status}`]"></div>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle>{{ $t(`invitation_status.${status}`) }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-container>
    </v-navigation-drawer>

    <!--FILTER BUTTON-->
    <v-fab-transition>
      <v-btn color="primary"
             fab
             bottom
             right
             v-if="!filterShown"
             @click="filterShown = true"
             class="filter-btn">
        <v-icon>
          fas fa-filter
        </v-icon>
      </v-btn>
    </v-fab-transition>
  </v-container>
</template>

<script>
import {computed, onMounted, ref, watch} from '@vue/composition-api';
import {DateTime} from 'luxon';
import {ACTION_TYPES} from "@/store/types";
import {Invitation, ItemType} from "@/store/models";
import {BOOKING_STATUS, INVITATION_STATUS} from "@/constants";
import _ from 'lodash'
import EditRecurringDialog from "@/components/EditRecurringDialog";
import BookingDetailModal from "@/components/BookingDetailModal";

export default {
  name: 'Schedule',
  components: {BookingDetailModal},
  setup(props, {root}) {
    const appBarShown = computed(() => root.$store.getters.appBarShown);

    const selectedDate = ref(new Date());
    const selectedDateString = computed(() => {
      return DateTime.fromJSDate(selectedDate.value).toFormat('yyyy-MM-dd');
    });
    const onDateSelected = function (value) {
      selectedDate.value = DateTime.fromFormat(value, 'yyyy-MM-dd').toJSDate();
    };
    watch(() => selectedDate.value, (newValue, oldValue) => {
      load();

      // let newDate = DateTime.fromJSDate(newValue);
      // let newEndDate = DateTime.fromJSDate(newValue).plus({
      //   'month': {'months': 1},
      //   '4day': {'days': 3},
      //   'day': {'days': 0},
      // }[calendarType.value]).startOf('day');
      //
      // let oldDate = DateTime.fromJSDate(oldValue);
      // let oldEndDate = DateTime.fromJSDate(oldValue).plus({
      //   'month': {'months': 1},
      //   '4day': {'days': 3},
      //   'day': {'days': 0},
      // }[calendarType.value]).startOf('day');
      //
      // if (!oldDate || newDate.year !== oldDate.year || newDate.month !== oldDate.month || newEndDate.year !== oldEndDate.year || newEndDate.month !== oldEndDate.month) {
      //   load();
      // }
    });
    const today = function () {
      selectedDate.value = new Date();
    };
    const prev = function () {
      selectedDate.value = DateTime.fromJSDate(selectedDate.value).minus({
        'month': {'months': 1},
        '4day': {'days': 4},
        'day': {'days': 1},
      }[calendarType.value]).startOf('day').toJSDate();
    };
    const next = function () {
      selectedDate.value = DateTime.fromJSDate(selectedDate.value).plus({
        'month': {'months': 1},
        '4day': {'days': 4},
        'day': {'days': 1},
      }[calendarType.value]).startOf('day').toJSDate();
    };

    const publicHolidays = ref([]);

    // Filter
    const me = computed(() => root.$store.getters.account);
    const roles = ref(['owner', 'host', 'invitee']);
    const roleOptions = ref([
      {text: 'Owner', value: 'owner'},
      {text: 'Host', value: 'host'},
      {text: 'Guest', value: 'invitee'},
    ])

    const selectedFacilityTypes = ref([]);
    const facilityTypeOptions = computed(() => {
      return ItemType.query().where((i) => !i.deleted).orderBy('ordering').get().map((t) => {
        return {text: t.name, value: t.id}
      })
    });
    const showCancelledBookings = ref(false);

    // Legends
    const itemTypes = computed(() => ItemType.query().where((i) => !i.deleted).where((i) => i.open_for_booking).orderBy('ordering').get());
    const bookingStatus = ref([
      BOOKING_STATUS.CONFIRMED,
      BOOKING_STATUS.PENDING_APPROVAL,
      BOOKING_STATUS.CANCELLED_BY_USER,
    ])
    const invitationStatus = ref([
      INVITATION_STATUS.ACCEPTED,
      INVITATION_STATUS.DECLINED,
      INVITATION_STATUS.TENTATIVE,
      INVITATION_STATUS.NEEDS_ACTION,
    ])

    const bookings = ref([]);
    const invitations = ref([]);
    const events = computed(() => {
      if (!me.value) return [];
      //
      // const monthStart = DateTime.fromJSDate(selectedDate.value).startOf('month')
      // const monthEnd = DateTime.fromJSDate(selectedDate.value).endOf('month')
      //
      // let bookings = Booking.query().where((b) => {
      //   let start = DateTime.fromISO(b.start_time);
      //
      //   return start >= monthStart && start <= monthEnd && _.some(roles.value.map((role) => {
      //     return {
      //       'owner': b.account.id === me.value.id,
      //       'host': b.hosts.indexOf(me.value.id) !== -1,
      //       'invitee': true,
      //     }[role];
      //   }));
      // }).withAllRecursive().all();

      let filtered = bookings.value;

      if (selectedFacilityTypes.value.length > 0) {
        filtered = _.filter(filtered, (b) => selectedFacilityTypes.value.indexOf(b.item.type.id) !== -1)
      }

      if (!showCancelledBookings.value) {
        filtered = _.filter(filtered, (b) => [
          BOOKING_STATUS.CONFIRMED,
          BOOKING_STATUS.PENDING_APPROVAL,
          BOOKING_STATUS.NO_SHOW,
        ].indexOf(b.status) !== -1)
      }

      return [
        ...filtered.map((b) => {
          let role = 'invitee';

          if (b.account.id === me.value.id) role = 'owner'
          else if (b.hosts.indexOf(me.value.id) !== -1) role = 'host';

          let my_invitation = _.find(invitations.value, {account_id: me.value.id, booking_id: b.id});

          return {
            ...b,
            name: b.title || b.item.name,
            start: DateTime.fromISO(b.start_time).toFormat('yyyy-MM-dd HH:mm'),
            end: DateTime.fromISO(b.end_time).toFormat('yyyy-MM-dd HH:mm'),
            role,
            my_invitation,
          }
        }),
        ...publicHolidays.value.map((ph) => {
          return {
            name: ph.name,
            title: ph.name,
            start: DateTime.fromISO(ph.date).startOf('day').toJSDate(),
            end: DateTime.fromISO(ph.date).endOf('day').toJSDate(),
            timed: false,
          }
        })
      ]
    })
    const eventLabel = function (event) {
      if (event.timed === false) {
        return event.title
      }

      let title = event.title;
      let hosts = event.hosts.map((h) => h.name).join(", ")

      if (!title && !hosts) {
        title = event.item.name;
      }

      return `${title || ''} ${title && hosts ? "-" : ''} ${hosts}`
    };
    const getEventColor = function (event) {
      // if (event.timed === false) {
      //   return '#E80B18';
      // }
      //
      // if (event.role === 'invitee') {
      //   let invitation = _.find(event.invitations, {'account_id': me.value.id})
      //   if (invitation && status !== INVITATION_STATUS.ACCEPTED) {
      //     return 'white';
      //   }
      // }

      return 'transparent';
    }
    const onEventClick = function (evt, booking) {
      if (booking.timed === false) return;

      showingBooking.value = {
        ...booking,
        hosts: _.orderBy(booking.hosts, [(h) => h.id === booking.account.id ? -1 : 0, 'name'], ['asc', 'asc'])
      };
      bookingDetailModalShown.value = true;
    }

    const showingBooking = ref(null);
    const showingBookingAdditionalItems = computed(() => {
      if (showingBooking.value) {
        return _.sortBy(showingBooking.value.additional_items, (i) => i.item_type_additional_item.ordering);
      }

      return [];
    })
    const showingBookingMyInvitation = computed(() => {
      if (!showingBooking.value) return false;

      return Invitation.query().where('booking_id', showingBooking.value.id).where('account_id', me.value.id).first();
    })
    const rsvp = async function (status) {
      let edit = 'this';

      if (showingBooking.value.bookingRecord.recurrence) {
        edit = await root.$dialog.showAndWait(EditRecurringDialog, {title: 'RSVP recurring booking'});
        if (edit === undefined || edit === false) return;
      }

      await root.$store.dispatch(ACTION_TYPES.RSVP_INVITATION, {
        id: showingBookingMyInvitation.value.id,
        response: status,
        edit,
      })

      await root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, root.$t('invitation.rsvp_success_msg', {
        status: root.$t(`invitation_status.${status}`),
        title: showingBooking.value.title
      }));
    };
    const editBooking = function () {
      root.$router.push({name: 'booking-details', params: {bookingId: showingBooking.value.id}});
    };
    const showingBookingInvitations = computed(() => {
      if (!showingBooking.value) return [];

      return _.sortBy(
          Invitation.query().where('booking_id', showingBooking.value.id).where((i) => i.status !== INVITATION_STATUS.CANCELLED).withAllRecursive().all(),
          [(i) => {
            return {
              [INVITATION_STATUS.ACCEPTED]: 0,
              [INVITATION_STATUS.TENTATIVE]: 1,
              [INVITATION_STATUS.DECLINED]: 2,
              [INVITATION_STATUS.NEEDS_ACTION]: 3,
            }[i.status]
          }, 'name']);
    })
    const showingBookingInvitationDescriptions = computed(() => {
      let counts = _.countBy(showingBookingInvitations.value, 'status');

      return _.keys(counts).map((status) => {
        return `${counts[status]} ${{
          [INVITATION_STATUS.NEEDS_ACTION]: ' awaiting',
          [INVITATION_STATUS.ACCEPTED]: ' yes',
          [INVITATION_STATUS.DECLINED]: ' no',
          [INVITATION_STATUS.TENTATIVE]: ' maybe',
        }[status]}`;
      }).join(', ');
    })
    const invitationIcon = function (invitation) {
      return {
        [INVITATION_STATUS.NEEDS_ACTION]: 'fas fa-clock',
        [INVITATION_STATUS.ACCEPTED]: 'fas fa-check',
        [INVITATION_STATUS.DECLINED]: 'fas fa-times',
        [INVITATION_STATUS.TENTATIVE]: 'fas fa-question',
      }[invitation.status];
    };
    const bookingDetailModalShown = ref(false);

    const calendarType = ref('month');
    watch(() => calendarType.value, () => {
      load();
    })
    const calendarTypes = ref([
      {text: 'Day', value: 'day'},
      {text: 'Week', value: 'week'},
      {text: 'Month', value: 'month'},
    ])
    const calendarStart = computed(() => {
      let d = DateTime.fromJSDate(selectedDate.value);

      return {
        'day': d.startOf('day').toJSDate(),
        '4day': d.startOf('day').toJSDate(),
        'week': d.startOf('week').toJSDate(),
        'month': d.startOf('month').toJSDate(),
      }[calendarType.value];
    })
    const calendarEnd = computed(() => {
      let d = DateTime.fromJSDate(selectedDate.value);

      return {
        'day': d.startOf('day').toJSDate(),
        '4day': d.startOf('day').plus({'days': 4}).toJSDate(),
        'week': d.endOf('week').toJSDate(),
        'month': d.endOf('month').toJSDate(),
      }[calendarType.value];
    })
    const calendarClickDate = function ({date}) {
      selectedDate.value = DateTime.fromISO(date).toJSDate();
      calendarType.value = {
        'month': 'day',
        '4day': 'day',
        'day': 'day',
      }[calendarType.value];
    };
    const calendarDate = computed(() => {
      if (calendarType.value === '4day') {
        // Check if across months
        let start = DateTime.fromJSDate(calendarStart.value)
        let end = DateTime.fromJSDate(calendarEnd.value)
        if (!start.hasSame(end, 'month')) {
          if (start.hasSame(end, 'year')) {
            return `${start.toFormat('LLLL')} - ${end.toFormat('LLLL yyyy')}`
          } else {
            return `${start.toFormat('LLLL  yyyy')} - ${end.toFormat('LLLL yyyy')}`
          }
        }
      }

      return {
        'day': DateTime.fromJSDate(calendarStart.value).toFormat('LLLL d, yyyy'),
        '4day': DateTime.fromJSDate(calendarStart.value).toFormat('LLLL yyyy'),
        'month': DateTime.fromJSDate(calendarStart.value).toFormat('LLLL yyyy'),
      } [calendarType.value]
    })

    const filterShown = ref(root.$vuetify.breakpoint.smAndUp);

    const datePickerAttributes = computed(() => {
      let attrs = [
        {
          key: 'today',
          highlight: {
            color: 'red',
            fillMode: 'light',
          }, dates: new Date(),
        },
      ];

      if (calendarType.value === 'week') {
        attrs.push({
          key: 'selected-week',
          highlight: {
            color: 'red',
            fillMode: 'light',
          },
          dates: {
            start: calendarStart.value,
            end: calendarEnd.value,
          },
        })
      }

      return attrs;
    })

    const load = async function () {
      const response = await root.$store.dispatch(ACTION_TYPES.GET_SCHEDULE, {
        start_date: DateTime.fromJSDate(calendarStart.value).toFormat('yyyy-MM-dd'),
        end_date: DateTime.fromJSDate(calendarEnd.value).startOf('day').plus({days: 1}).toFormat('yyyy-MM-dd'),
      });

      let bookingRecordMap = {};
      response.body.booking_records.forEach((r) => {
        bookingRecordMap[r.id] = r;
      })

      bookings.value = response.body.bookings.map((b) => {
        return {
          ...b,
          bookingRecord: bookingRecordMap[b.booking_record],
        }
      });

      invitations.value = response.body.invitations;

      publicHolidays.value = response.body.holidays;
    };

    watch(() => root.$store.getters.account, (newValue) => {
      if (newValue) {
        load();
      }
    }, {immediate: true})

    onMounted(() => {
      root.$store.dispatch(ACTION_TYPES.SET_APP_BAR_TITLE, root.$t('app_drawer.schedule'));
      // load();
    })

    return {
      appBarShown,

      selectedDate,
      selectedDateString,
      onDateSelected,
      today,
      prev,
      next,

      roles,
      roleOptions,
      selectedFacilityTypes,
      facilityTypeOptions,
      showCancelledBookings,

      itemTypes,
      bookingStatus,
      invitationStatus,

      events,
      eventLabel,
      getEventColor,
      onEventClick,

      showingBooking,
      showingBookingAdditionalItems,
      showingBookingMyInvitation,
      rsvp,
      editBooking,
      showingBookingInvitations,
      showingBookingInvitationDescriptions,
      invitationIcon,
      bookingDetailModalShown,

      calendarType,
      calendarTypes,
      calendarStart,
      calendarEnd,
      calendarClickDate,
      calendarDate,

      filterShown,

      datePickerAttributes,

      BOOKING_STATUS,
      INVITATION_STATUS,
    }
  },
}
</script>

<style lang="less">
.schedule-container {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;

  .filter-container {
    height: 100% !important;
    display: flex;
    flex-direction: column;

    .vc-container {
      background-color: transparent !important;
      border-color: transparent !important;
    }
  }

  .filter-btn {
    position: fixed;
    right: 24px;
    bottom: 24px;
    z-index: 10;
    transition: bottom 0.5s ease-out;
  }

  .CONFIRMED {
    background: #25D366;
  }

  .PENDING_APPROVAL {
    background: #FEB12A;
  }

  .REJECTED, .CANCELLED_BY_USER, .CANCELLED_BY_ADMIN, .NO_SHOW {
    background: #CBCBCB;
  }

  .item-type-icon {
    filter: grayscale(1) invert(1);
    flex: 0 0 auto;
  }

  .HOLIDAY {
    background: #E80B18;
    padding-left: 4px;
  }

  .rsvp_bubble {
    width: 8px;
    height: 8px;
    border-radius: 10px;

    &.legend {
      width: 16px;
      height: 16px;
    }

    &.RSVP_NEEDS-ACTION {
      border: solid 1px #25D366;
    }

    &.RSVP_ACCEPTED {
      background: #25D366;
    }

    &.RSVP_DECLINED {
      background: #CBCBCB;
    }

    &.RSVP_TENTATIVE {
      background: #FEB12A;
    }
  }
}

.schedule-booking-detail {
  .schedule-booking-detail-title {
    color: white;
  }

  .CONFIRMED {
    background: #25D366;
  }

  .PENDING_APPROVAL {
    background: #FEB12A;
  }

  .REJECTED, .CANCELLED_BY_USER, .CANCELLED_BY_ADMIN, .NO_SHOW {
    background: #CBCBCB;
  }
}
</style>
