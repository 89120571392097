<template>
  <v-container fluid class="availability-grid pa-0">
    <v-app-bar v-model="showFloatingCategories"
               fixed
               :class="['category-floating-header',{'app-bar-shown-md-and-up': appBarShown && $vuetify.breakpoint.mdAndUp}, {'app-bar-shown-sm-and-down': appBarShown && $vuetify.breakpoint.smAndDown}]"
               :height="categoryHeight"
               :style="floatCategoryStyle">
      <div class="overflow-x-auto scroll-wrapper">
        <div class="v-calendar v-calendar-daily v-calendar-category theme--light">
          <div class="v-calendar-daily__head">
            <div class="v-calendar-daily__intervals-head"></div>
            <div class="v-calendar-daily_head-day v-present">
              <div class="v-calendar-category__columns">
                <div v-for="category in categories"
                     :key="category"
                     class="v-calendar-category__column-header">
                  <v-container class="text-center"
                               @click="$emit('click:category', {event: $event, item: itemMap[category], item_id: category})">
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-icon color="rgb(40, 97, 169)">mdi-information-outline</v-icon>
                      </v-col>
                      <v-col cols="12">
                        <label>
                          {{ itemMap[category].name }}
                        </label>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-app-bar>

    <!--    <v-row class="mb-8">-->
    <!--      <v-col class="availability-grid-legend-col" cols="12" sm="4">-->
    <!--        <div class="availability-grid-legend"-->
    <!--             style="background-color: rgba(0, 0, 0, 0.25);"><label></label>-->
    <!--        </div>-->
    <!--        <label>UNAVAILABLE</label>-->
    <!--      </v-col>-->
    <!--      <v-col class="availability-grid-legend-col" cols="12" sm="4">-->
    <!--        <div class="availability-grid-legend" style="background-color: rgba(111, 193, 182, 0.5)"><label></label></div>-->
    <!--        <label>AVAILABLE</label>-->
    <!--      </v-col>-->
    <!--      <v-col class="availability-grid-legend-col" cols="12" sm="4">-->
    <!--        <div class="availability-grid-legend required-approval" style="background-color: rgba(111, 193, 182, 0.5)">-->
    <!--          <label></label></div>-->
    <!--        <label>NEED APPROVAL</label>-->
    <!--      </v-col>-->
    <!--    </v-row>-->

    <div class="availability-grid-legend-wrapper">
      <div class="availability-grid-legend-col">
        <div class="availability-grid-legend UNAVAILABLE"><label></label>
        </div>
        <label>UNAVAILABLE</label>
      </div>
      <div class="availability-grid-legend-col">
        <div class="availability-grid-legend AVAILABLE"><label></label></div>
        <label>AVAILABLE</label>
      </div>
      <div class="availability-grid-legend-col">
        <div class="availability-grid-legend required-approval" style="background-color: var(--need-approval)">
          <label></label></div>
        <label>NEED APPROVAL</label>
      </div>
      <div class="availability-grid-legend-col">
        <div class="availability-grid-legend OCCUPIED">
          <label></label></div>
        <label>CONFIRMED</label>
      </div>
    </div>


    <v-row class="text-right" justify="end" v-if="Object.values(priceTierMap).length > 0">
      <v-col cols="12" md="4">
        <div v-for="tier in priceTierMap" v-bind:key="tier.id" class="text-right">
          <span style="font-weight: bold">{{ tier.legend }}</span> - {{ tier.name }} - ${{ tier.price }}
        </div>
      </v-col>
    </v-row>
    <v-row class="availability-grid-calendar">
      <v-col class="overflow-x-auto">
        <label v-if="!filteredSessions.length">NO SESSION AVAILABLE</label>
        <v-calendar type="category"
                    :interval-height="intervalHeight"
                    :interval-width="45"
                    :first-interval="firstInterval"
                    :interval-count="intervalCount"
                    :interval-minutes="intervalMinutes"
                    :start="calendarStart"
                    :end="calendarEnd"
                    :categories="categories"
                    :events="filteredSessions"
                    :event-color="getSessionColor"
                    @click:event="sessionClicked"
                    :event-overlap-threshold="1"
                    v-if="filteredSessions.length">
          <template v-slot:category="{category}">
            <v-container class="text-center fixed-categories" fluid
                         @click="$emit('click:category', {event: $event, item: itemMap[category], item_id: category})">
              <v-row no-gutters>
                <v-col cols="12">
                  <v-icon color="rgb(40, 97, 169)">mdi-information-outline</v-icon>
                </v-col>
                <v-col cols="12">
                  <label>
                    {{ itemMap[category].name }}
                  </label>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:event="{event}">
            <!--            <v-container class="font-weight-bold text-center">{{ event.price_tier ? priceTierMap[event.price_tier].legend : '' }}</v-container>-->
            <v-container fluid
                         :class="['font-weight-bold', 'text-center', 'pa-0', 'fill-height', 'flex-column', {'required-approval': event.required_approval}]">
              <v-row no-gutters class="flex-grow-1 d-flex" style="width: 100% !important;">
                <v-col class="availability-grid-event-content fill-height" v-html="eventLabel(event)"
                       v-if="event.booking">
                </v-col>
                <div v-if="event.pending_bookings && event.pending_bookings.length > 0"
                     class="availability-grid-pending-bookings">
                  <v-tooltip top>
                    <template #activator="{on, attrs}">
                      <v-icon v-on="on"
                              @click.stop="showPendingBookings(event)"
                              v-bind="attrs">mdi-account-clock-outline
                      </v-icon>
                    </template>
                    {{ `${$t('booking_status.PENDING_APPROVAL')}: ${event.pending_bookings.length}` }}
                  </v-tooltip>
                </div>
              </v-row>
            </v-container>
          </template>
        </v-calendar>
        <!--            </v-sheet>-->
      </v-col>
    </v-row>

    <!--BOOKING DETAIL-->
    <BookingDetailModal :shown="bookingDetailModalShown"
                        @dismiss="bookingDetailModalShown = false"
                        :booking="showingBooking"></BookingDetailModal>

    <!--PENDING BOOKING LIST-->
    <PendingBookingsModal :shown="pendingBookingsModalShown"
                          @dismiss="pendingBookingsModalShown = false"
                          :session="pendingSession"></PendingBookingsModal>
  </v-container>
</template>

<script>
import {computed, onBeforeUnmount, onMounted, ref, watch} from '@vue/composition-api';
import {DateTime} from "luxon";
import _ from "lodash";
import {BOOKING_STATUS, INVITATION_ROLE, SESSION_STATUS} from "@/constants";
import {ACTION_TYPES} from "@/store/types";
import $ from "jquery";
import Vue from 'vue';
import BookingDetailModal from "@/components/BookingDetailModal";
import PendingBookingsModal from "@/components/PendingBookingsModal";

export default {
  name: 'AvailabilityGrid',
  components: {PendingBookingsModal, BookingDetailModal},
  props: {
    mode: {
      type: String,
      default: 'day', // "day" / "item"
    },
    startDate: {
      type: String,
      default: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
    },
    endDate: {
      type: String,
      default: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
    },
    startTime: String,
    endTime: String,
    intervalMinutes: {
      type: Number,
      default: 60,
    },
    intervalHeight: {
      type: Number,
      default: 64,
    },
    extraParams: Object,
    consecutiveHours: {
      type: String,
      default: "0",
    },
    showBookingDetails: Boolean,
    value: Array,
    sessionsSelected: Array,
    selectedItemId: String,
    refreshTrigger: Boolean,
  },
  setup(props, {root, emit}) {
    const account = computed(() => root.$store.getters.account);
    const appBarShown = computed(() => root.$store.getters.appBarShown);
    const roomBookingSettings = computed(() => root.$store.getters.roomBookingSettings);
    const showFloatingCategories = ref(false);
    const categoryHeaderX = ref(0);
    const categoryHeaderWidth = ref(0);
    const categoryHeight = ref(48);
    const floatCategoryStyle = computed(() => {
      return {
        left: `${categoryHeaderX.value - 1}px`,
        width: `${categoryHeaderWidth.value}px`,
      }
    })

    function onScroll(e) {
      let header = $('.availability-grid-calendar .v-calendar-daily__head');
      if (header[0]) header = header[0];
      if (header && header.getBoundingClientRect) {
        let rect = header.getBoundingClientRect();
        showFloatingCategories.value = rect.top <= 56;
        categoryHeight.value = rect.height;
        categoryHeaderX.value = rect.x;
        categoryHeaderWidth.value = rect.width;
      }
    }

    const throttled = _.throttle(onScroll, 100);

    const items = ref([]);
    const itemMap = computed(() => {
      let map = {};

      items.value.forEach((i) => {
        map[i.id] = i;
      });

      return map;
    })

    const bookings = ref([]);
    const bookingMap = ref({});

    const priceTiers = ref([]);
    const priceTierMap = computed(() => {
      let map = {};

      priceTiers.value.forEach((i) => {
        map[i.id] = i;
      });

      return map;
    });

    const myAccountGroups = computed(() => {
      return root.$store.getters.account.account_groups;
    })

    const sessions = ref([]);
    const sessionStart = computed(() => {
      return _.min(sessions.value.map((s) => s.start));
    });
    const sessionEnd = computed(() => {
      return _.max(sessions.value.map((s) => s.end));
    })
    const filteredSessions = computed(() => {
      let filtered = sessions.value;

      // Filter out sessions with my pending bookings
      let sessionsWithMyPendingBookings = _.filter(filtered, (s) => _.some(s.pending_bookings, (b) => b.account.id === account.value.id));
      let myPendingBookings = [];

      let sessionIdsWithMyPendingBookings = sessionsWithMyPendingBookings.map((s) => {
        myPendingBookings = _.concat(myPendingBookings, _.filter(s.pending_bookings, (b) => b.account.id === account.value.id).map((b) => {
          return {
            name: '',
            category: s.item_id,
            start: DateTime.fromISO(b.start_time).toFormat('yyyy-MM-dd HH:mm'),
            end: DateTime.fromISO(b.checkout_time || b.end_time).toFormat('yyyy-MM-dd HH:mm'),
            booking: b,
            pending_bookings: s.pending_bookings,
          }
        }));

        return s.id;
      })
      myPendingBookings = _.uniqBy(myPendingBookings, 'booking.id');

      // Filter out pending sessions
      filtered = _.filter(filtered, (s) => sessionIdsWithMyPendingBookings.indexOf(s.id) === -1);

      // Replace Occupied Sessions with Bookings
      const occupiedByItem = _.groupBy(_.filter(filtered, (s) => !!s.booking), 'item_id');
      let bookings = [];
      _.forEach(occupiedByItem, (sessions, item_id) => {
        bookings = _.concat(bookings, _.uniqBy(sessions, 'booking.id').map((s) => {
          return {
            name: '',
            category: item_id,
            start: DateTime.fromISO(_.max([s.booking.start_time, sessionStart.value])).toFormat('yyyy-MM-dd HH:mm'),
            end: DateTime.fromISO(_.min([s.booking.checkout_time || s.booking.end_time, sessionEnd.value])).toFormat('yyyy-MM-dd HH:mm'),
            booking: s.booking,
          }
        }))
      })

      // Filter out occupied sessions
      filtered = _.filter(filtered, (s) => !s.booking);

      //
      if (roomBookingSettings.value && roomBookingSettings.value.skip_approval_booking_period >= 0) {
        let require_approvals = _.remove(filtered, 'required_approval');

        const limit = roomBookingSettings.value.skip_approval_booking_period;
        const today = DateTime.fromJSDate(new Date()).startOf('day');

        require_approvals = require_approvals.map((s) => {
          return {
            ...s,
            required_approval: DateTime.fromISO(s.start).startOf('day').diff(today, 'days').values.days >= limit,
          }
        })

        filtered = _.concat(filtered, require_approvals);
      }

      return [
        ...filtered.map((s) => {
          return {
            ...s,
            category: s.item_id,
            start: DateTime.fromISO(s.start).toFormat('yyyy-MM-dd HH:mm'),
            end: DateTime.fromISO(s.end).toFormat('yyyy-MM-dd HH:mm'),
          }
        }),
        ...myPendingBookings,
        ...bookings,
      ];
    });
    const categories = computed(() => {
      // return Item.query().orderBy('name').get().map((i) => {
      //   return i.id;
      // });
      let itemIds = new Set();
      filteredSessions.value.forEach((s) => {
        itemIds.add(s.category);
      });
      return items.value.filter((item) => {
        return itemIds.has(item.id);
      }).sort((a, b) => a.name.localeCompare(b.name)).map((i) => {
        return i.id;
      });
    });
    const eventLabel = function (event) {
      if (event.booking) {
        if (event.booking.timed === false) {
          return event.booking.title
        }

        let title = event.booking.title;
        let hosts = event.booking.hosts.map((h) => h.name).join(", ")
        let chairs = _.filter(event.invitations, (i) => i.role === INVITATION_ROLE.CHAIR);
        if (chairs.length > 0) {
          hosts = chairs.map((c) => c.account.name).join(", ")
        }

        if (!title && !hosts) {
          title = event.booking.item.name;
        }

        return `${root.$parseDate(event.booking.start_time, 'HH:mm')}-${root.$parseDate(event.booking.end_time, 'HH:mm')}<br> ${title || ''} ${title && hosts ? "-" : ''} ${hosts} `
      }

      return '';
    };

    const firstInterval = computed(() => {
      let minStart = _.min(filteredSessions.value.map((s) => {
        let start = DateTime.fromFormat(s.start, 'yyyy-MM-dd HH:mm');
        return (start.toSeconds() + start.offset * 60) % (24 * 3600);
      })) || 0;

      return Math.floor(minStart / (props.intervalMinutes * 60)) - 1;
    });
    const intervalCount = computed(() => {
      let a = filteredSessions.value.map((s) => {
        let start = DateTime.fromFormat(s.start, 'yyyy-MM-dd HH:mm');
        let end = DateTime.fromFormat(s.end, 'yyyy-MM-dd HH:mm');

        if (start.hasSame(end, 'day')) {
          return Math.ceil((end.toSeconds() + end.offset * 60) % (24 * 3600) / (props.intervalMinutes * 60));
        } else {
          return Math.ceil(24 * (props.intervalMinutes / 60));
        }
      });

      return (_.max(a) || Math.ceil(24 * (props.intervalMinutes / 60))) - firstInterval.value + 1;
    });

    const calendarStart = computed(() => {
      if (props.mode === 'day') {
        return props.startDate;
      }
    });
    const calendarEnd = computed(() => {
      if (props.mode === 'day') {
        return props.endDate;
      }
    })

    const getSessionColor = function (s) {
      if (s.require_privilege && _.intersection(itemMap.value[s.category].privileged_groups, myAccountGroups.value).length === 0) {
        return 'UNAVAILABLE';
      }

      if (selectedSessionIds.value.indexOf(s.id) !== -1) {
        // return '#1a8ffb';
        return 'SELECTED';
      }

      if (s.booking) {
        return s.booking.status === BOOKING_STATUS.PENDING_APPROVAL ? SESSION_STATUS.PENDING_APPROVAL : SESSION_STATUS.OCCUPIED;
      }

      if (DateTime.fromFormat(s.end, 'yyyy-MM-dd HH:mm') <= DateTime.fromJSDate(new Date) || s.status !== SESSION_STATUS.AVAILABLE) {
        return 'UNAVAILABLE';
      }

      return s.status;
    };

    const selectedSessions = ref([]);
    watch(() => selectedSessions.value, (newValue) => {
      emit('update:sessions-selected', newValue);
    })
    const selectedSessionIds = computed(() => selectedSessions.value.map((s) => s.id));
    watch(() => selectedSessionIds.value, (newValue) => {
      emit('input', newValue);
      if (newValue.length > 0) {
        emit('update:selected-item-id', selectedSessions.value[0].item_id);
      } else {
        emit('update:selected-item-id', null);
      }
    })

    const sessionClicked = function ({event}) {
      if (event.require_privilege && _.intersection(itemMap.value[event.category].privileged_groups, myAccountGroups.value).length === 0) {
        return
      }

      if (DateTime.fromFormat(event.end, 'yyyy-MM-dd HH:mm') <= DateTime.fromJSDate(new Date) || event.status !== SESSION_STATUS.AVAILABLE) {
        if (event.booking) {
          showingBooking.value = {
            ...event.booking,
            hosts: _.orderBy(event.booking.hosts, [(h) => h.id === event.booking.account.id ? -1 : 0, 'name'], ['asc', 'asc'])
          };
          bookingDetailModalShown.value = true;
        }

        return;
      }

      let idx = -1;

      selectedSessions.value.forEach((s, index) => {
        if (s.id === event.id) {
          idx = index;
        }
      });

      let newSelectedSessions = selectedSessions.value;
      if (idx === -1) {
        if (newSelectedSessions.length > 0) {
          // Check if selected session has the same category with existing sessions
          if (newSelectedSessions[0].category !== event.category) {
            newSelectedSessions = [];
          }

          newSelectedSessions.push(event);

          // Select all sessions in between if more than 1 sessions are selected
          if (newSelectedSessions.length > 1) {
            let start = _.minBy(newSelectedSessions, 'start').start;
            let end = _.maxBy(newSelectedSessions, 'end').end;

            newSelectedSessions = _.filter(filteredSessions.value, (s) => {
              return s.category === event.category && s.start >= start && s.end <= end;
            });

            // Check if any selected sessions already been booked
            if (_.some(newSelectedSessions, (session) => session.booking)) {
              newSelectedSessions = [event];
            }
          }
        } else {
          newSelectedSessions.push(event);
        }

        selectedSessions.value = _.sortBy(newSelectedSessions, [(s) => {
          return s.start;
        }]);
      } else {
        selectedSessions.value = [];
      }
    };

    const bookingDetailModalShown = ref(false);
    const showingBooking = ref(null);

    const pendingBookingsModalShown = ref(false);
    const pendingSession = ref(null);
    const showPendingBookings = function (session) {
      pendingSession.value = session;
      pendingBookingsModalShown.value = true;
    }

    const load = async function () {
      selectedSessions.value = [];
      const response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
        url: 'client/search/',
        params: {
          from_date: DateTime.fromFormat(calendarStart.value, 'yyyy-MM-dd').toISO(),
          to_date: DateTime.fromFormat(calendarEnd.value, 'yyyy-MM-dd').startOf('day').plus({days: 1}).toISO(),
          from_time: props.startTime,
          to_time: props.endTime,

          ...props.extraParams,
        }
      });

      items.value = response.body.items;
      bookings.value = response.body.bookings;
      let map = {};
      bookings.value.forEach((i) => {
        map[i.id] = i;
      });
      bookingMap.value = map;

      priceTiers.value = response.body.price_tiers;
      sessions.value = _.sortBy(response.body.sessions, 'start').map((s) => {
        return {
          ...s,
          booking: bookingMap.value[s.booking],
          pending_bookings: s.pending_bookings.map((id) => {
            return bookingMap.value[id]
          })
        }
      });

      Vue.nextTick(() => {
        let headers = $('.v-calendar-category__columns');

        headers.scroll((e) => {
          headers.scrollLeft(e.target.scrollLeft);
        });
      });

      emit('update:refresh-trigger', false);
    }

    watch(() => props.refreshTrigger, (newValue) => {
      if (newValue) {
        load();
      }
    }, {immediate: true});

    onMounted(() => {
      window.addEventListener('scroll', throttled);
      window.addEventListener('resize', throttled);
    });
    onBeforeUnmount(() => {
      window.removeEventListener('scroll', throttled);
      window.removeEventListener('resize', throttled);
    })

    return {
      appBarShown,
      showFloatingCategories,
      categoryHeight,
      floatCategoryStyle,


      itemMap,

      priceTierMap,

      myAccountGroups,
      filteredSessions,
      categories,
      eventLabel,

      sessionStart,
      sessionEnd,
      firstInterval,
      intervalCount,

      calendarStart,
      calendarEnd,

      getSessionColor,

      selectedSessions,
      sessionClicked,

      bookingDetailModalShown,
      showingBooking,

      pendingBookingsModalShown,
      pendingSession,
      showPendingBookings,
    }
  },
}
</script>

<style lang="less">
.availability-grid {
  .v-calendar-daily__scroll-area::-webkit-scrollbar {
    display: none;
  }

  .v-event-timed-container {
    margin-right: 0 !important;
    overflow: hidden;
  }

  .availability-grid-legend-col {
    flex: 0 0 auto;
    position: relative;
    padding: 0;
    padding-left: 62px;
    text-align: left;
    margin-right: 12px;
    margin-bottom: 12px;

    label {
      line-height: 40px;
    }
  }

  .SELECTED {
    background: var(--selected);

    .required-approval {
      background: var(--need-approval-selected);
    }
  }

  .AVAILABLE {
    background-color: var(--available);

    .required-approval {
      background: var(--need-approval);
    }
  }

  .OCCUPIED {
    background-color: var(--occupied);

    .availability-grid-event-content {
      color: white !important;
    }
  }

  .PENDING_APPROVAL {
    background-color: var(--occupied);
    opacity: 0.5;
  }

  .UNAVAILABLE, .EXCLUDED, .EXPIRED {
    background-color: var(--unavailable);
  }

  .availability-grid-event-content {
    white-space: pre-wrap;
    line-height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
  }

  .availability-grid-pending-bookings {
    position: absolute;
    right: 2px;
    top: 2px;
  }

  .availability-grid-legend {
    display: inline-block;
    width: 45px;
    height: 45px;
    /*max-width: 11vw;*/
    max-height: 11vw;
    /*line-height: 11vw;*/
    color: white;

    position: absolute;
    left: 12px;
    top: 0;
    padding: 0;

    label {
      margin: 0;
      //position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @media only screen and (max-width: 600px) {
        font-size: 0.9rem;
      }
    }
  }

  .v-calendar-daily_head-weekday, .v-calendar-daily_head-day-label {
    display: none;
  }

  .v-calendar-category .v-calendar-category__columns {
    overflow-x: auto;

    .v-calendar-category__column {
      min-width: 150px;
    }
  }

  .v-calendar-daily_head-day .v-calendar-category__columns .v-calendar-category__column-header {
    min-width: 150px;
  }
}

.category-floating-header {
  top: 0 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  //transition: none;
  z-index: 4 !important;

  &.app-bar-shown-md-and-up {
    top: 64px !important;
  }

  &.app-bar-shown-sm-and-down {
    top: 56px !important;
  }

  .scroll-wrapper {
    width: 100%;
    //padding-left: 36px;
    //padding-right: 36px;
  }

  .v-toolbar__content {
    background-color: transparent;
    padding: 0;
  }

  .v-calendar-daily__head {
    margin-right: 0;

    .v-calendar-daily__intervals-head {
      width: 45px;
    }
  }
}
</style>
