<template>
  <v-container class="register-device">
    <v-form class="flex-grow-1">
      <v-text-field outlined
                    readonly
                    :label="$t('device.id')"
                    clearable
                    v-model="deviceId">
        <template #append-outer>
          <v-tooltip top>
            <template #activator="{on, attrs}">
              <v-btn icon
                     v-on="on"
                     v-bind="attrs"
                     @click="showQrCodeModal">
                <v-icon>fas fa-qrcode</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('device.scan_qr_code') }}</span>
          </v-tooltip>
        </template>
      </v-text-field>
      <v-select outlined
                label="Location"
                :items="locationOptions"
                v-model="selectedLocationId"></v-select>
      <v-select outlined
                label="Region"
                :items="regionOptions"
                v-model="selectedRegionId"></v-select>
      <v-select outlined
                label="Item"
                :items="itemOptions"
                v-model="selectedItemId"></v-select>
    </v-form>

    <v-row class="flex-grow-0" no-gutters>
      <v-btn color="danger"
             @click="unlinkDevice"
             depressed
             large
             :disabled="!existingDevice">
        {{ $t('device.unlink') }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary"
             @click="linkDevice"
             depressed
             large
             :disabled="!deviceId || !selectedRegionId">
        {{ $t('device.link') }}
      </v-btn>
    </v-row>

    <BGModal :shown="scanQrCodeModalShown">
      <template #content>
        <QrcodeStream :camera="qrCodeReaderSuspended? 'off' : 'auto'"
                      @decode="onQrCodeDecode"
                      class="qr-code-reader">
          <div class="qr-code-reader-overlay" v-if="qrCodeReaderSuspended">
            <label>PROCESSING...</label>
          </div>
        </QrcodeStream>
      </template>
      <template #footer>
        <v-row no-gutters>
          <v-btn color="secondary"
                 @click="hideQrCodeModal"
                 class="mr-3"
                 depressed
                 large>
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-row>
      </template>
    </BGModal>


  </v-container>
</template>

<script>
import BGModal from "@/components/BGModal";
import {QrcodeStream} from 'vue-qrcode-reader'
import {computed, onMounted, ref, watch} from '@vue/composition-api';
import {ACTION_TYPES} from "@/store/types";

export default {
  name: 'RegisterDevice',
  components: {BGModal, QrcodeStream},
  setup(props, {root}) {
    const deviceId = ref(null);
    const existingDevice = ref(null);
    const getDevice = async function () {
      let response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
        url: 'device/',
        params: {device_id: deviceId.value},
      });

      existingDevice.value = response.body.device;

      if (response.body.device && response.body.device.item) {
        let item = response.body.device.item;
        selectedLocationId.value = item.region.location.id;
        selectedRegionId.value = item.region.id;
        selectedItemId.value = item.id;
      } else {
        selectedLocationId.value = null;
        selectedRegionId.value = null;
        selectedItemId.value = null;
      }
    }
    watch(() => deviceId.value, async (newValue) => {
      if (newValue) {
        await getDevice();
      } else {
        existingDevice.value = null;
      }
    });

    const scanQrCodeModalShown = ref(false);
    const showQrCodeModal = function () {
      scanQrCodeModalShown.value = true;
      qrCodeReaderSuspended.value = false;
    };
    const qrCodeReaderSuspended = ref(false);
    const onQrCodeDecode = function (s) {
      qrCodeReaderSuspended.value = true;
      scanQrCodeModalShown.value = false;
      deviceId.value = s;
    }
    const hideQrCodeModal = function () {
      qrCodeReaderSuspended.value = true;
      scanQrCodeModalShown.value = false;
    }

    const locations = ref([]);
    const selectedLocationId = ref(null);
    const locationOptions = computed(() => {
      return locations.value.map((l) => {
        return {text: l.name, value: l.id};
      })
    })

    const regions = ref([]);
    const selectedRegionId = ref(null);
    const regionOptions = computed(() => {
      return regions.value.filter((r) => {
        return r.location_id === selectedLocationId.value
      }).map((r) => {
        return {text: r.name, value: r.id};
      })
    })

    const items = ref([]);
    const selectedItemId = ref(null);
    const itemOptions = computed(() => {
      return items.value.filter((i) => {
        return i.region_id === selectedRegionId.value
      }).map((l) => {
        return {text: l.name, value: l.id};
      })
    })

    const getFacilities = async function () {
      let response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
        url: 'admin/facilities/',
      });

      locations.value = response.body.locations;
      regions.value = response.body.regions;
      items.value = response.body.items;
    };

    const unlinkDevice = async function () {
      if (await root.$dialog.confirm({text: root.$t('device.unlink_confirm_msg')})) {
        await root.$store.dispatch(ACTION_TYPES.CALL_API, {
          url: 'device/unregister/',
          params: {device_id: deviceId.value},
        });
        await root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, root.$t('device.unlink_success_msg'));

        await getDevice();
      }
    }

    const linkDevice = async function () {
      if (await root.$dialog.confirm({text: root.$t('device.link_confirm_msg')})) {
        await root.$store.dispatch(ACTION_TYPES.CALL_API, {
          url: 'device/register/',
          params: {
            device_id: deviceId.value,
            region_id: selectedRegionId.value,
            item_id: selectedItemId.value,
          },
        });
        await root.$store.dispatch(ACTION_TYPES.SHOW_SNACKBAR, root.$t('device.link_success_msg'));

        await getDevice();
      }
    }

    onMounted(() => {
      getFacilities();
    })

    return {
      deviceId,
      existingDevice,

      scanQrCodeModalShown,
      showQrCodeModal,
      qrCodeReaderSuspended,
      onQrCodeDecode,
      hideQrCodeModal,

      selectedLocationId,
      locationOptions,

      selectedRegionId,
      regionOptions,

      selectedItemId,
      itemOptions,

      unlinkDevice,
      linkDevice,
    }
  },
}
</script>

<style lang="less">
.register-device {
  height: 100%;
  display: flex;
  flex-direction: column;

  .v-text-field--outlined .v-input__append-outer {
    margin-top: 8px;
  }
}
</style>
