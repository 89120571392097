<template>
  <v-container class="pending-approval" fluid>
    <v-row>
      <v-col>
        <DatePickerInput range
                         v-model="dateRange"></DatePickerInput>
      </v-col>
    </v-row>

    <RemotePaginatedList full-height
                         :headers="headers"
                         multi-sort
                         data-url="client/bookings/"
                         data-key="bookings"
                         :extra-params="extraParams"
                         @click:row="onBookingClicked"
                         :refresh-trigger.sync="refreshTrigger"
                         mobile-breakpoint="0"
                         v-bind="$attrs">
      <template v-slot:item.created_at="{value}">
        {{ $parseDate(value) }}
      </template>
      <template v-slot:item.start_time="{value}">
        {{ $parseDate(value, 'yyyy-MM-dd') }}
      </template>
      <template v-slot:item.time="{item}">
        {{ $parseDate(item.start_time, 'HH:mm') + ' - ' + $parseDate(item.end_time, 'HH:mm') }}
      </template>
      <template v-slot:item.status="{value}">
        {{ $t(`booking_status.${value}`) }}
      </template>
      <template #item.role="{item}">
        {{ bookingOwnedByMe(item) ? 'Owner' : 'Host' }}
      </template>
    </RemotePaginatedList>
  </v-container>
</template>
<script>
import {ref, watch} from '@vue/composition-api';
import BannerFrame from "@/components/BannerFrame";
import RemotePaginatedList from "@/components/RemotePaginatedList";
import DatePickerInput from "@/components/DatePickerInput";
import {DateTime} from "luxon";

export default {
  name: 'MyBookings',
  components: {DatePickerInput, RemotePaginatedList, BannerFrame},
  setup(props, {root}) {
    const now = DateTime.fromJSDate(new Date())
    const dateRange = ref([
      now.toFormat('yyyy-MM-dd'),
      now.plus({days: 30}).toFormat('yyyy-MM-dd'),
    ]);
    watch(() => dateRange.value, (newValue) => {
      if (newValue.length === 2) {
        extraParams.value = {
          from_date: newValue[0],
          to_date: newValue[1],
        }
        refresh();
      }
    })
    const extraParams = ref({})

    const refresh = function () {
      refreshTrigger.value = true;
    };
    const refreshTrigger = ref(false);

    const headers = ref([
      {text: root.$t('booking.created_at'), value: 'created_at'},
      {text: root.$t('booking.item'), value: 'item.name'},
      {text: root.$t('booking.date'), value: 'start_time'},
      {text: root.$t('booking.time'), value: 'time', sortable: false},
      {text: root.$t('booking.title'), value: 'title'},
      {text: root.$t('booking.status'), value: 'status'},
      // {text: root.$t('booking.role'), value: 'role', sortable: false},
    ]);
    const bookingOwnedByMe = function (booking) {
      return root.$store.getters.account.id === booking.account.id;
    }
    const onBookingClicked = function (item) {
      root.$router.push({name: 'booking-details', params: {bookingId: item.id}});
    };

    watch(() => root.$store.getters.account, async (newValue) => {
      if (newValue) {
        refresh();
      }
    }, {immediate: true})

    return {
      dateRange,
      extraParams,
      refreshTrigger,
      headers,
      bookingOwnedByMe,
      onBookingClicked,
    }
  }
}
</script>
