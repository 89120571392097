<template>
  <BannerFrame :label="$t('app_drawer.my_invitations')"
               flex-content
               no-padding>
    <RemotePaginatedList full-height
                         :headers="headers"
                         multi-sort
                         data-url="client/invitations/"
                         data-key="invitations"
                         @click:row="onInvitationClicked"
                         v-bind="$attrs">
      <template v-slot:item.created_at="{value}">
        {{ $parseDate(value) }}
      </template>
      <template v-slot:item.booking.start_time="{value}">
        {{ $parseDate(value) }}
      </template>
      <template v-slot:item.time="{item}">
        {{ $parseDate(item.start_time, 'HH:mm') + ' - ' + $parseDate(item.end_time, 'HH:mm') }}
      </template>
      <template v-slot:item.status="{value}">
        {{ $t(`invitation_status.${value}`) }}
      </template>
    </RemotePaginatedList>
  </BannerFrame>
</template>
<script>
import {computed, onMounted, ref} from '@vue/composition-api';
import BannerFrame from "@/components/BannerFrame";
import {Booking} from "@/store/models";
import {ACTION_TYPES} from "@/store/types";
import RemotePaginatedList from "@/components/RemotePaginatedList";

export default {
  name: 'MyInvitations',
  components: {RemotePaginatedList, BannerFrame},
  setup(props, {root}) {

    const headers = ref([
      {text: root.$t('invitation.created_at'), value: 'created_at'},
      {text: root.$t('invitation.booking'), value: 'booking.title'},
      {text: root.$t('invitation.organizer'), value: 'booking.account.name'},
      {text: root.$t('booking.datetime'), value: 'booking.start_time'},
      {text: root.$t('invitation.status'), value: 'status'},
    ]);
    const onInvitationClicked = function (item) {
      root.$router.push({name: 'invitation-details', params: {invitationId: item.id}});
    };

    return {
      headers,
      onInvitationClicked,
    }
  }
}
</script>
