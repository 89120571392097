<template>
  <v-card class="availability-grid">
    <v-card-subtitle>
      {{ cardTitle }}
    </v-card-subtitle>
    <v-card-text style="height: 70vh;">
      <v-calendar type="category"
                  interval-height="32"
                  interval-width="45"
                  :first-interval="firstInterval"
                  :interval-count="intervalCount"
                  interval-minutes="30"
                  :start="bookingDate"
                  :end="bookingDate"
                  :categories="categories"
                  :events="filteredSessions"
                  :event-color="getSessionColor"
                  @click:event="sessionClicked"
                  :event-overlap-threshold="1"
                  v-if="booking && filteredSessions.length">
        <template v-slot:category="{category}">
          <v-container class="text-center fixed-categories" fluid
                       @click="$emit('click:category', {event: $event, item: itemMap[category], item_id: category})">
            <v-row no-gutters>
              <v-col cols="12">
                <v-icon color="rgb(40, 97, 169)">mdi-information-outline</v-icon>
              </v-col>
              <v-col cols="12">
                <label>
                  {{ itemMap[category].name }}
                </label>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:event="{event}">
          <!--            <v-container class="font-weight-bold text-center">{{ event.price_tier ? priceTierMap[event.price_tier].legend : '' }}</v-container>-->
          <v-container fluid
                       :class="['font-weight-bold', 'text-center', 'pa-0', 'fill-height', 'flex-column', {'required-approval': event.required_approval}, {'target-booking': event.booking && event.booking.id === booking.id}]">
            <v-row no-gutters class="flex-grow-1 d-flex" style="width: 100% !important;">
              <v-col class="availability-grid-event-content fill-height" v-html="eventLabel(event)"
                     v-if="event.booking">
              </v-col>
              <div v-if="event.pending_bookings && event.pending_bookings.length > 0"
                   class="availability-grid-pending-bookings">
                <v-tooltip top>
                  <template #activator="{on, attrs}">
                    <v-icon v-on="on"
                            @click.stop="showPendingBookings(event)"
                            v-bind="attrs">mdi-account-clock-outline
                    </v-icon>
                  </template>
                  {{ `${$t('booking_status.PENDING_APPROVAL')}: ${event.pending_bookings.length}` }}
                </v-tooltip>
              </div>
            </v-row>
          </v-container>
        </template>
      </v-calendar>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text
             color="primary"
             :disabled="!canSave"
             @click="save">
        {{ $t('ok') }}
      </v-btn>
    </v-card-actions>

    <!--BOOKING DETAIL-->
    <BookingDetailModal :shown="bookingDetailModalShown"
                        @dismiss="bookingDetailModalShown = false"
                        :booking="showingBooking"
                        hide-edit></BookingDetailModal>
  </v-card>
</template>

<script>
import {computed, onMounted, ref} from "@vue/composition-api";
import {ACTION_TYPES} from "@/store/types";
import _ from "lodash";
import {DateTime} from "luxon";
import {BOOKING_STATUS, INVITATION_ROLE, SESSION_STATUS} from "@/constants";
import BookingDetailModal from "@/components/BookingDetailModal";

export default {
  name: 'ChangeBookingTimeDialog',
  components: {BookingDetailModal},
  props: {
    bookingId: String,
    intervalMinutes: {
      type: Number,
      default: () => 30,
    }
  },
  setup(props, {root, emit}) {
    const cardTitle = computed(() => {
      if (!allowExtension.value) {
        if (!allowShortening.value) {
          return '';
        } else {
          return root.$t('booking.edit_time_only_shortening_title');
        }
      }

      if (!allowShortening.value) {
        return root.$t('booking.edit_time_only_extension_title');
      } else {
        return root.$t('booking.edit_time_both_title');
      }
    });
    const allowExtension = ref(false);
    const allowShortening = ref(false);

    const itemMap = computed(() => {
      if (booking.value) {
        return {[booking.value.item.id]: booking.value.item}
      }
      return {};
    });

    const account = computed(() => {
      return root.$store.getters.account;
    })
    const myAccountGroups = computed(() => {
      return root.$store.getters.account.account_groups;
    })
    const roomBookingSettings = computed(() => root.$store.getters.roomBookingSettings);

    const booking = ref(null);
    const bookingDate = computed(() => {
      if (booking.value) {
        return DateTime.fromISO(booking.value.start_time).toFormat('yyyy-MM-dd')
      }
    })
    const originalOccupiedSessionId = ref([]);
    const bookingMap = ref(null);
    const sessions = ref(null);
    const sessionStart = computed(() => {
      return _.min(sessions.value.map((s) => s.start));
    });
    const sessionEnd = computed(() => {
      return _.max(sessions.value.map((s) => s.end));
    })
    const filteredSessions = computed(() => {
      let filtered = sessions.value;

      //// Pending Bookings
      // Filter out sessions with my pending bookings
      let sessionsWithMyPendingBookings = _.filter(filtered, (s) => _.some(s.pending_bookings, (b) => b.account.id === account.value.id));
      let myPendingBookings = [];

      let sessionIdsWithMyPendingBookings = sessionsWithMyPendingBookings.map((s) => {
        myPendingBookings = _.concat(myPendingBookings, _.filter(s.pending_bookings, (b) => b.account.id === account.value.id).map((b) => {
          return {
            name: '',
            category: s.item_id,
            start: DateTime.fromISO(b.start_time).toFormat('yyyy-MM-dd HH:mm'),
            end: DateTime.fromISO(b.checkout_time || b.end_time).toFormat('yyyy-MM-dd HH:mm'),
            booking: b,
            pending_bookings: s.pending_bookings,
          }
        }));

        return s.id;
      })
      myPendingBookings = _.uniqBy(myPendingBookings, 'booking.id');
      // Filter out pending sessions
      filtered = _.filter(filtered, (s) => sessionIdsWithMyPendingBookings.indexOf(s.id) === -1);

      //// Confirmed Bookings
      // Replace Occupied Sessions with Bookings other than the target booking
      const occupiedByItem = _.groupBy(_.filter(filtered, (s) => !!s.booking && s.booking.id !== booking.value.id), 'item_id');
      let bookings = [];
      _.forEach(occupiedByItem, (sessions, item_id) => {
        bookings = _.concat(bookings, _.uniqBy(sessions, 'booking.id').map((s) => {
          return {
            name: '',
            category: item_id,
            start: DateTime.fromISO(_.max([s.booking.start_time, sessionStart.value])).toFormat('yyyy-MM-dd HH:mm'),
            end: DateTime.fromISO(_.min([s.booking.checkout_time || s.booking.end_time, sessionEnd.value])).toFormat('yyyy-MM-dd HH:mm'),
            booking: s.booking,
          }
        }))
      })
      // Filter out occupied sessions
      filtered = _.filter(filtered, (s) => !s.booking || s.booking.id === booking.value.id);

      //
      if (roomBookingSettings.value && roomBookingSettings.value.skip_approval_booking_period >= 0) {
        let require_approvals = _.remove(filtered, 'required_approval');

        const limit = roomBookingSettings.value.skip_approval_booking_period;
        const today = DateTime.fromJSDate(new Date()).startOf('day');

        require_approvals = require_approvals.map((s) => {
          return {
            ...s,
            required_approval: DateTime.fromISO(s.start).startOf('day').diff(today, 'days').values.days >= limit,
          }
        })

        filtered = _.concat(filtered, require_approvals);
      }

      return [
        ...filtered.map((s) => {
          return {
            ...s,
            category: s.item_id,
            start: DateTime.fromISO(s.start).toFormat('yyyy-MM-dd HH:mm'),
            end: DateTime.fromISO(s.end).toFormat('yyyy-MM-dd HH:mm'),
          }
        }),
        ...myPendingBookings,
        ...bookings,
      ];
    })

    const firstInterval = computed(() => {
      let minStart = _.min(filteredSessions.value.map((s) => {
        let start = DateTime.fromFormat(s.start, 'yyyy-MM-dd HH:mm');
        return (start.toSeconds() + start.offset * 60) % (24 * 3600);
      })) || 0;

      return Math.floor(minStart / (props.intervalMinutes * 60)) - 1;
    });
    const intervalCount = computed(() => {
      let a = filteredSessions.value.map((s) => {
        let start = DateTime.fromFormat(s.start, 'yyyy-MM-dd HH:mm');
        let end = DateTime.fromFormat(s.end, 'yyyy-MM-dd HH:mm');

        if (start.hasSame(end, 'day')) {
          return Math.ceil((end.toSeconds() + end.offset * 60) % (24 * 3600) / (props.intervalMinutes * 60));
        } else {
          return Math.ceil(24 * (props.intervalMinutes / 60));
        }
      });

      return (_.max(a) || Math.ceil(24 * (props.intervalMinutes / 60))) - firstInterval.value + 1;
    });
    const categories = computed(() => {
      return booking.value ? [booking.value.item] : [];
    });
    const eventLabel = function (event) {
      if (event.booking) {
        if (event.booking.timed === false) {
          return event.booking.title
        }

        let title = event.booking.title;
        let hosts = event.booking.hosts.map((h) => h.name).join(", ")
        let chairs = _.filter(event.invitations, (i) => i.role === INVITATION_ROLE.CHAIR);
        if (chairs.length > 0) {
          hosts = chairs.map((c) => c.account.name).join(", ")
        }

        if (!title && !hosts) {
          title = event.booking.item.name;
        }

        return `${root.$parseDate(event.booking.start_time, 'HH:mm')}-${root.$parseDate(event.booking.end_time, 'HH:mm')}<br> ${title || ''} ${title && hosts ? "-" : ''} ${hosts} `
      }

      return '';
    };

    const getSessionColor = function (s) {
      if (s.require_privilege && _.intersection(itemMap.value[s.category].privileged_groups, myAccountGroups.value).length === 0) {
        return 'UNAVAILABLE';
      }

      if (selectedSessionIds.value.indexOf(s.id) !== -1) {
        // return '#1a8ffb';
        return 'SELECTED';
      }

      if (s.booking) {
        if (s.booking.id === booking.value.id) return 'TARGET-BOOKING';

        return s.booking.status === BOOKING_STATUS.PENDING_APPROVAL ? SESSION_STATUS.PENDING_APPROVAL : SESSION_STATUS.OCCUPIED;
      }

      if (DateTime.fromFormat(s.end, 'yyyy-MM-dd HH:mm') <= DateTime.fromJSDate(new Date) || s.status !== SESSION_STATUS.AVAILABLE) {
        return 'UNAVAILABLE';
      }

      return s.status;
    };

    const bookingDetailModalShown = ref(false);
    const showingBooking = ref(null);

    const selectedSessions = ref([]);
    const selectedSessionIds = computed(() => selectedSessions.value.map((s) => s.id));
    const sessionClicked = function ({event}) {
      if (event.require_privilege && _.intersection(itemMap.value[event.category].privileged_groups, myAccountGroups.value).length === 0) {
        return
      }

      // Sessions before now or with booking
      if (DateTime.fromFormat(event.end, 'yyyy-MM-dd HH:mm') <= DateTime.fromJSDate(new Date) || event.status !== SESSION_STATUS.AVAILABLE) {
        if (event.booking && event.booking.id !== booking.value.id) {
          showingBooking.value = {
            ...event.booking,
            hosts: _.orderBy(event.booking.hosts, [(h) => h.id === event.booking.account.id ? -1 : 0, 'name'], ['asc', 'asc'])
          };
          bookingDetailModalShown.value = true;
        }

        return;
      }

      let idx = -1;
      selectedSessions.value.forEach((s, index) => {
        if (s.id === event.id) {
          idx = index;
        }
      });

      let newSelectedSessions = selectedSessions.value;
      if (idx === -1) {
        // Check if booking extension is allowed
        if (!allowExtension.value && (!event.booking || event.booking.id !== booking.value.id)) {
          root.$showAlertMessage('Extension of booking is not allowed')
          return;
        }

        if (newSelectedSessions.length > 0) {
          // Check if selected session has the same category with existing sessions
          if (newSelectedSessions[0].category !== event.category) {
            newSelectedSessions = [];
          }

          newSelectedSessions.push(event);

          // Select all sessions in between if more than 1 sessions are selected
          if (newSelectedSessions.length > 1) {
            let start = _.minBy(newSelectedSessions, 'start').start;
            let end = _.maxBy(newSelectedSessions, 'end').end;

            newSelectedSessions = _.filter(filteredSessions.value, (s) => {
              return s.category === event.category && s.start >= start && s.end <= end;
            });

            // Check if any selected sessions already been booked
            if (_.some(newSelectedSessions, (session) => session.booking && session.booking.id !== booking.value.id)) {
              newSelectedSessions = [event];
            }
          }
        } else {
          newSelectedSessions.push(event);
        }

        selectedSessions.value = _.sortBy(newSelectedSessions, [(s) => {
          return s.start;
        }]);
      } else {
        if (selectedSessions.value.length === 1) {
          // Check if clicked session is the only one left
          root.$showAlertMessage('Invalid Selection')
        } else if (!allowShortening.value && event.booking && event.booking.id === booking.value.id) {
          // Check if shortening of booking is allowed
          root.$showAlertMessage('Shortening of booking is not allowed')
        } else if (idx === 0 || idx === (selectedSessions.value.length - 1)) {
          // Check if clicked session is in either end of the selected sessions
          selectedSessions.value.splice(idx, 1);
        } else {
          root.$showAlertMessage('Invalid Selection')
        }
      }
    };

    const pendingBookingsModalShown = ref(false);
    const pendingSession = ref(null);
    const showPendingBookings = function (session) {
      pendingSession.value = session;
      pendingBookingsModalShown.value = true;
    }

    onMounted(async () => {
      const response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
        url: 'client/bookings/',
        params: {
          booking_id: props.bookingId,
          with_sessions: true,
        },
      });

      booking.value = response.body.bookings[0];
      allowExtension.value = booking.value.item.allow_booking_extension;
      allowShortening.value = booking.value.item.allow_booking_shortening;

      let map = {};
      response.body.related_bookings.forEach((b) => {
        map[b.id] = b;
      });
      bookingMap.value = map;

      // Filter out sessions occupied by the booking
      const sessionsNotOccupiedByBooking = _.filter(response.body.sessions, (s) => !s.booking || s.booking !== booking.value.id);

      // Replace sessions occupied by the booking
      const sessionsOccupiedByBooking = _.filter(response.body.sessions, (s) => !!s.booking && s.booking === booking.value.id).map((s) => {
        return {
          ...s,
          status: SESSION_STATUS.AVAILABLE,
        }
      });
      originalOccupiedSessionId.value = sessionsOccupiedByBooking.map((s) => s.id);

      sessions.value = _.sortBy(_.concat(sessionsNotOccupiedByBooking, sessionsOccupiedByBooking), 'start').map((s) => {
        return {
          ...s,
          booking: bookingMap.value[s.booking],
          pending_bookings: s.pending_bookings.map((id) => {
            return bookingMap.value[id]
          })
        }
      });

      selectedSessions.value = sessionsOccupiedByBooking.map((s) => {
        return {
          ...s,
          category: s.item_id,
          start: DateTime.fromISO(s.start).toFormat('yyyy-MM-dd HH:mm'),
          end: DateTime.fromISO(s.end).toFormat('yyyy-MM-dd HH:mm'),
          status: SESSION_STATUS.AVAILABLE,
        }
      });

      // Scroll to sessions
      let bookingStart = DateTime.fromISO(booking.value.start_time)
      let diffInMinutes = bookingStart.diff(DateTime.fromISO(sessionStart.value), 'minutes').values.minutes
      const offset = (diffInMinutes / 30 - 2) * 32;

      setTimeout(() => {
        document.body.getElementsByClassName('v-calendar-daily__scroll-area')[0].scrollTo({
          top: offset,
          behavior: 'smooth'
        })
      }, 50);
    })

    const canSave = computed(() => {
      return _.difference(selectedSessionIds.value, originalOccupiedSessionId.value).length > 0 || _.difference(originalOccupiedSessionId.value, selectedSessionIds.value).length > 0;
    })
    const save = async function () {
      const response = await root.$store.dispatch(ACTION_TYPES.CALL_API, {
        url: 'client/bookings/save_time/',
        params: {
          booking_id: booking.value.id,
          session_ids: selectedSessionIds.value,
        },
      });

      if (response.body.success) {
        emit('submit', true);
      }
    }
    return {
      cardTitle,
      booking,
      bookingDate,
      originalOccupiedSessionId,
      itemMap,
      bookingMap,
      filteredSessions,
      firstInterval,
      intervalCount,
      categories,
      eventLabel,

      getSessionColor,
      bookingDetailModalShown,
      showingBooking,
      sessionClicked,

      pendingBookingsModalShown,
      pendingSession,
      showPendingBookings,

      canSave,
      save,
    }
  },
}
</script>

<style lang="less">
.availability-grid {
  .TARGET-BOOKING {
    opacity: 0.5;
    background-color: var(--occupied);

    .availability-grid-event-content {
      color: white !important;
    }
  }
}
</style>
