<template>
  <BGModal :shown="dialogShown"
           :title="$t('instant_book.title')"
           max-width="500px"
           no-fullscreen>
    <template #content>
      <v-container class="search" fluid>
        <AvailabilityGrid :start-date="selectedDate"
                          :end-date="selectedDate"
                          :start-time="selectedFromTime"
                          :extra-params="{
                            item_id: itemId,
                          }"
                          show-booking-details
                          :refresh-trigger.sync="refreshTrigger"
                          v-model="selectedSessionIds"></AvailabilityGrid>
      </v-container>
    </template>
    <template #footer>
      <v-row no-gutters>
        <v-btn color="secondary"
               @click="cancel"
               class="mr-3"
               depressed
               large>
          {{ $t('back') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary"
               class="ml-4"
               :disabled="selectedSessionIds.length === 0"
               @click="book"
               depressed
               large>BOOK
        </v-btn>
      </v-row>
    </template>
  </BGModal>
</template>
<script>
import BGModal from "@/components/BGModal";
import {ref, watch} from "@vue/composition-api";
import {ACTION_TYPES} from "@/store/types";
import AvailabilityGrid from "@/components/AvailabilityGrid";
import {DateTime} from "luxon";
import {BookingRecord} from "@/store/models";

export default {
  name: 'InstantBookModal',
  components: {AvailabilityGrid, BGModal},
  props: {
    shown: Boolean,
    itemId: String,
    sessionId: String,
  },
  setup(props, {root, emit}) {
    const dialogShown = ref(props.shown);

    // Init
    watch(() => props.shown, async (newValue, oldValue) => {
      if (newValue !== oldValue) {
        dialogShown.value = newValue;

        if (newValue) {
          selectedSessionIds.value = [props.sessionId];
          selectedFromTime.value = DateTime.fromJSDate(new Date()).startOf('hour').toFormat('HH:mm');
          refreshTrigger.value = true;
        }
      }
    });
    const cancel = function () {
      emit('cancel');
    };

    const selectedDate = ref(DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'));
    const selectedFromTime = ref(DateTime.fromJSDate(new Date()).startOf('hour').toFormat('HH:mm'));
    const selectedSessionIds = ref([]);
    const refreshTrigger = ref(false);

    const book = async function () {
      let response = await root.$store.dispatch(ACTION_TYPES.BOOK_SESSIONS, {
        session_ids: selectedSessionIds.value,
      });

      if (response.body.booking_record) {
        await BookingRecord.insert({
          data: [response.body.booking_record],
        });
      }

      root.$router.push({name: 'booking-details', params: {bookingId: response.body.booking_record.bookings[0].id}});
    }

    return {
      dialogShown,
      cancel,

      selectedDate,
      selectedFromTime,
      selectedSessionIds,
      refreshTrigger,
      book,
    }
  }
}
</script>
