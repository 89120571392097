import {ACTION_TYPES} from "@/store/types";

export const appBarTitleModule = {
    state: {
        appBarShown: false,
        appBarContent: '',
    },
    mutations: {
        setAppBarShown(state, shown) {
            state.appBarShown = shown;
        },
        setAppBarContent(state, content) {
            state.appBarContent = content;
        },
        clearAppBarContent(state) {
            state.appBarContent = '';
        },
    },
    actions: {
        [ACTION_TYPES.SET_APP_BAR_SHOWN]: {
            root: true,
            handler({commit}, shown) {
                commit('setAppBarShown', shown);
            },
        },
        [ACTION_TYPES.SET_APP_BAR_TITLE]: {
            root: true,
            handler({commit}, content) {
                commit('setAppBarContent', content);
            },
        },
        [ACTION_TYPES.CLEAR_APP_BAR_TITLE]: {
            root: true,
            handler({commit}, content) {
                commit('clearAppBarContent', content);
            },
        },
    },
    getters: {
        appBarShown(state) {
            return state.appBarShown;
        },
        appBarTitle(state) {
            return state.appBarContent;
        },
    },
}
