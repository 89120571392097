<template>
  <v-container class="pending-approval" fluid>
    <v-row>
      <v-col>
        <DatePickerInput range
                         v-model="dateRange"></DatePickerInput>
      </v-col>
    </v-row>

    <RemotePaginatedList full-height
                         :headers="headers"
                         multi-sort
                         hide-default-footer
                         :items-per-page="-1"
                         data-url="client/pending_approval/"
                         data-key="bookings"
                         :extra-params="extraParams"
                         @click:row="onBookingClicked"
                         :refresh-trigger.sync="refreshTrigger">
      <template v-slot:item.created_at="{value}">
        {{ $parseDate(value) }}
      </template>
      <template v-slot:item.start_time="{item}">
        {{ $parseDate(item.start_time, 'yyyy-MM-dd HH:mm') + ' - ' + $parseDate(item.end_time, 'HH:mm')}}
      </template>
      <template v-slot:item.item="{item}">
        {{ item.item.name }}
      </template>
    </RemotePaginatedList>
  </v-container>
</template>
<script>
import {computed, onMounted, ref, watch} from '@vue/composition-api';
import BannerFrame from "@/components/BannerFrame";
import {Booking} from "@/store/models";
import BGModal from "@/components/BGModal";
import RemotePaginatedList from "@/components/RemotePaginatedList";
import DatePickerInput from "@/components/DatePickerInput";
import {DateTime} from 'luxon';

export default {
  name: 'PendingApproval',
  components: {DatePickerInput, RemotePaginatedList, BGModal, BannerFrame},
  setup(props, {root}) {
    const now = DateTime.fromJSDate(new Date())
    const dateRange = ref([
      now.startOf('month').toFormat('yyyy-MM-dd'),
      now.endOf('month').toFormat('yyyy-MM-dd'),
    ]);
    watch(()=> dateRange.value, (newValue) => {
      if (newValue.length === 2) {
        extraParams.value = {
          from_date: newValue[0],
          to_date: newValue[1],
        }
        load();
      }
    })
    const extraParams = ref({})

    const load = async function () {
      refreshTrigger.value = true;
    };
    const bookings = computed(() => {
      return Booking.query().orderBy('created_at', 'desc').withAllRecursive().get();
    });

    const headers = ref([
      {text: root.$t('booking.created_at'), value: 'created_at', sortable: true},
      {text: root.$t('booking.account'), value: 'account.name', sortable: true},
      {text: root.$t('booking.item'), value: 'item.name', sortable: true},
      {text: root.$t('booking.date'), value: 'start_time', sortable: true},
      {text: root.$t('booking.title'), value: 'title', sortable: true},
    ]);
    const refreshTrigger = ref(false);

    const onBookingClicked = function (item) {
      root.$router.push({name: 'approve-booking', params: {bookingId: item.id}})
    };

    watch(() => root.$store.getters.account, async (newValue) => {
      if (newValue) {
        // await load();
      }
    }, {immediate: true})

    onMounted(()=> {
      // const now = DateTime.fromJSDate(new Date())
      //
      // dateRange.value = [
      //   now.startOf('month').toFormat('yyyy-MM-dd'),
      //   now.endOf('month').toFormat('yyyy-MM-dd'),
      // ];
    })

    return {
      dateRange,
      extraParams,
      bookings,
      headers,
      refreshTrigger,
      onBookingClicked,
    }
  }
}
</script>

<style lang="less">
.pending-approval {
  height: 100%;
  display: flex;
  flex-direction: column;

  .pending-approval-date-filter {
    display: flex;
    flex: 0 0 auto;
  }
}
</style>
