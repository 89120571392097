<template>
  <v-dialog v-model="dialogShown"
            :persistent="persistent"
            transition="slide-x-reverse-transition"
            :max-width="maxWidth || '960px'"
            class="pa-0"
            :fullscreen="fullscreen">
    <div :class="['bg-modal','pa-0', 'd-flex', 'flex-column', {'fullscreen': fullscreen}, contentClass]">
      <v-container class="bg-modal-header-row" fluid v-if="showHeader">
        <slot name="headers" v-if="$slots['headers']" v-bind:fullscreen="fullscreen"></slot>
        <label v-else>{{ title }}</label>
        <v-btn icon
               @click="fullscreen = false"
               v-if="fullscreen && !noFullscreen"
               key="exit-fullscreen"
               class="fullscreen-btn">
          <v-icon>fas fa-compress-arrows-alt</v-icon>
        </v-btn>
        <v-btn icon
               @click="fullscreen = true"
               v-if="!fullscreen && !noFullscreen"
               key="enter-fullscreen"
               class="fullscreen-btn">
          <v-icon>fas fa-expand-arrows-alt</v-icon>
        </v-btn>
      </v-container>
      <v-container :class="['bg-modal-content-row', 'pa-0', {'with-header': showHeader}, {'with-tabs': fixedTabs}]" fluid>
        <slot name="content" v-bind:fullscreen="fullscreen"></slot>
      </v-container>
      <v-container class="bg-modal-footer-row" fluid
                   v-if="$slots['footer']">
        <slot name="footer" v-bind:fullscreen="fullscreen"></slot>
      </v-container>
    </div>
  </v-dialog>
</template>

<script>
import {ref, computed, watch} from '@vue/composition-api'

export default {
  name: 'BGModal',
  props: {
    title: String,
    shown: Boolean,
    maxWidth: String,
    fixedTabs: Boolean,
    noFullscreen: Boolean,
    persistent: {
      type: Boolean,
      default: true,
    },
    contentClass: String,
  },
  setup(props, {root, emit}) {
    const dialogShown = ref(false);
    const fullscreen = ref(root.$vuetify.breakpoint.smAndDown);

    watch(() => props.shown, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        dialogShown.value = newValue;
      }
    });

    watch(()=> dialogShown.value, (newValue) => {
      if (!newValue) {
        emit('dismiss')
      }
    })

    const showHeader = computed(() => {
      return root.$slots['headers'] || props.title;
    })

    return {
      dialogShown,
      fullscreen,

      showHeader,
    }
  }
}
</script>

<style lang="less">
.bg-modal {
  background-color: white;
  //min-height: 90vh;
  height: 90vh;
  position: relative;

  &.fullscreen {
    height: 100% !important;
  }

  .bg-modal-header-row {
    //background-color: #ebecee;
    background-color: var(--v-primary-base);
    color: var(--v-secondary-base);
    width: 100%;
    height: 64px;
    padding-left: 32px;
    z-index: 10;

    label {
      font-weight: bold;
      line-height: 48px;
      font-size: 150%;
    }

    .fullscreen-btn {
      position: absolute;
      right: 18px;
      top: 18px;
    }
  }

  .bg-modal-content-row {
    height: 100%;
    //padding-bottom: 64px;
    overflow-y: auto;

    &.with-header {
      //padding-top: 64px;
      //height: calc(100% - 64px) !important;
    }

    &.with-tabs {
      .v-tabs {
        .v-tabs-bar {
          flex: 0 0 auto;
        }

        .v-tabs-items {
          height: calc(100% - 48px);
          flex: 1 1 0;

          .v-window__container {
            height: 100% !important;

            .v-window-item {
              height: 100% !important;
            }
          }
        }
      }
    }
  }

  .bg-modal-footer-row {
    background-color: #ebecee;
    //position: absolute;
    //bottom: 0;
    //left: 0;
    width: 100%;
    height: 64px;
    z-index: 2;
  }
}
</style>
