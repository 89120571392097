import VuexORM, {Model} from '@vuex-orm/core'

const database = new VuexORM.Database();

export class Announcement extends Model {
    static entity = 'announcements'
    static primaryKey = 'id'

    static fields() {
        return {
            id: this.uid(),
            title: this.attr(),
            content: this.attr(),
        }
    }
}

export class User extends Model {
    static entity = 'users'
    static primaryKey = 'id'

    static fields() {
        return {
            id: this.attr(),
            domain: this.attr(),
            username: this.attr(),
            name: this.attr(),
            email: this.attr(),
            title: this.attr(),
            mobile: this.attr(),
        }
    }
}

export class Location extends Model {
    static entity = 'locations'
    static primaryKey = 'id'

    static fields() {
        return {
            id: this.uid(),
            name: this.attr(),
            address: this.attr(),

            regions: this.hasMany(Region, 'location_id'),

            created_at: this.attr(),
            updated_at: this.attr(),
        }
    }
}

export class Region extends Model {
    static entity = 'regions'
    static primaryKey = 'id'

    static fields() {
        return {
            id: this.uid(),
            name: this.attr(),
            location_id: this.uid(),

            location: this.belongsTo(Location, 'location_id'),
            items: this.hasMany(Item, 'region_id'),

            created_at: this.attr(),
            updated_at: this.attr(),
        }
    }
}

export class ItemType extends Model {
    static entity = 'itemTypes'
    static primaryKey = 'id'

    static fields() {
        return {
            id: this.uid(),
            name: this.attr(),
            open_for_booking: this.boolean(),
            banner: this.attr(),
            icon: this.attr(),
            ordering: this.attr(),
            remarks: this.attr(),
            default_location: this.attr(),
            default_region: this.attr(),
            auto_select_first: this.attr(),
            save_previous_filter: this.attr(),

            enabled_features: this.attr(),
            required_features: this.attr(),

            attributes: this.hasMany(ItemTypeAttributes, 'item_type'),
            additional_items: this.hasMany(ItemTypeAdditionalItem, 'item_type'),
            price_tiers: this.hasMany(PriceTier, 'item_type'),

            created_at: this.attr(),
            updated_at: this.attr(),
            deleted: this.attr(),
        }
    }
}

export class ItemTypeAttributes extends Model {
    static entity = 'itemTypeAttributes'
    static primaryKey = 'id'

    static fields() {
        return {
            id: this.uid(),
            item_type: this.uid(),
            name: this.attr(),
            category: this.attr(),
            type: this.attr(),
            icon: this.attr(),
            ordering: this.attr(),

            itemType: this.belongsTo(ItemType, 'item_type'),

            created_at: this.attr(),
            updated_at: this.attr(),
        }
    }
}

export class ItemTypeAdditionalItem extends Model {
    static entity = 'itemTypeAdditionalItem'
    static primaryKey = 'id'

    static fields() {
        return {
            id: this.uid(),
            item_type: this.uid(),
            name: this.attr(),
            price: this.attr(),
            type: this.attr(),
            meta: this.attr(),
            hint: this.attr(),
            required: this.attr(),
            ordering: this.attr(),
            required_by: this.attr(),

            itemType: this.belongsTo(ItemType, 'item_type'),

            created_at: this.attr(),
            updated_at: this.attr(),
        }
    }
}

export class PriceTier extends Model {
    static entity = 'priceTiers'
    static primaryKey = 'id'

    static fields() {
        return {
            id: this.uid(),
            item_type: this.uid(),
            name: this.attr(),
            price: this.attr(),
            legend: this.attr(),

            itemType: this.belongsTo(ItemType, 'item_type'),

            created_at: this.attr(),
            updated_at: this.attr(),
        }
    }
}

export class Item extends Model {
    static entity = 'items'
    static primaryKey = 'id'

    static fields() {
        return {
            id: this.uid(),
            type_id: this.uid(),
            region_id: this.uid(),

            name: this.attr(),
            enabled: this.attr(),
            descriptions: this.attr(),
            price_tier_map: this.attr(),
            schedule_id: this.attr(),

            enabled_features: this.attr(),
            required_features: this.attr(),

            type: this.belongsTo(ItemType, 'type_id'),
            region: this.belongsTo(Region, 'region_id'),
            attributes: this.hasMany(ItemAttribute, 'item'),
            itemAdmins: this.belongsToMany(User, ItemApprover, 'item_id', 'user_id'),
            // schedule: this.belongsTo(WeekSchedule, 'schedule_id'),


            created_at: this.attr(),
            updated_at: this.attr(),
        }
    }
}

export class ItemApprover extends Model {
    static entity = 'itemApprover'

    static primaryKey = ['item_id', 'user_id']

    static fields() {
        return {
            item_id: this.uid(),
            user_id: this.attr(),
        }
    }
}

export class ItemAttribute extends Model {
    static entity = 'itemAttributes'
    static primaryKey = 'id'

    static fields() {
        return {
            id: this.uid(),
            item: this.uid(),
            type_attribute: this.uid(),
            value: this.attr(),
            number_value: this.attr(),

            typeAttribute: this.belongsTo(ItemTypeAttributes, 'type_attribute'),

            created_at: this.attr(),
            updated_at: this.attr(),
        }
    }
}

export class Session extends Model {
    static entity = 'sessions'
    static primaryKey = 'id'

    static fields() {
        return {
            id: this.uid(),
            start: this.attr(),
            end: this.attr(),
            item_id: this.attr(),
            price_tier: this.attr(),
            booking_id: this.attr(),
            status: this.attr(),

            item: this.hasOne(Item, 'item_id'),
            priceTier: this.hasOne(PriceTier, 'price_tier'),
        }
    }
}

export class BookingRecord extends Model {
    static entity = 'bookingRecords'
    static primaryKey = 'id'

    static fields() {
        return {
            id: this.uid(),
            account: this.uid(),
            status: this.attr(),

            bookings: this.hasMany(Booking, 'booking_record'),
            recurrence: this.attr(),
            recur_until: this.attr(),

            created_at: this.attr(),
            updated_at: this.attr(),
        }
    }
}

export class Booking extends Model {
    static entity = 'bookings'
    static primaryKey = 'id'

    static fields() {
        return {
            id: this.attr(),
            account: this.attr(),
            booking_record: this.attr(),
            item_id: this.attr(),
            title: this.attr(),
            start_time: this.attr(),
            end_time: this.attr(),
            fee: this.attr(),
            hosts: this.attr(),
            status: this.attr(),

            checkin_time: this.attr(),
            checkout_time: this.attr(),
            checkin_pin: this.attr(),
            remarks: this.attr(),

            accountObj: this.belongsTo(User, 'account'),
            item: this.belongsTo(Item, 'item_id'),
            bookingRecord: this.belongsTo(BookingRecord, 'booking_record'),
            invitations: this.hasMany(Invitation, 'booking_id'),

            created_at: this.attr(),
            updated_at: this.attr(),
        }
    }
}

export class Invitation extends Model {
    static entity = 'invitations'
    static primaryKey = 'id'

    static fields() {
        return {
            id: this.attr(),
            account_id: this.attr(),
            booking_id: this.attr(),
            role: this.attr(),
            status: this.attr(),

            account: this.belongsTo(User, 'account_id'),
            booking: this.belongsTo(Booking, 'booking_id'),

            created_at: this.attr(),
            updated_at: this.attr(),
        }
    }
}

database.register(Announcement)
database.register(User)
database.register(Location)
database.register(Region)
database.register(ItemType)
database.register(ItemTypeAttributes)
database.register(ItemTypeAdditionalItem)
database.register(PriceTier)
database.register(Item)
database.register(ItemApprover)
database.register(ItemAttribute)
database.register(Session)
database.register(BookingRecord)
database.register(Booking)
database.register(Invitation)

export default VuexORM.install(database);
