var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","content-class":"pending-bookings"},model:{value:(_vm.dialogShown),callback:function ($$v) {_vm.dialogShown=$$v},expression:"dialogShown"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('booking_status.PENDING_APPROVAL')))]),_c('v-card-text',[_c('v-row',[_c('v-col',[(_vm.session)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.session.pending_bookings,"items-per-page":-1,"hide-default-footer":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$parseDate(value))+" ")]}},{key:"item.start_time",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$parseDate(value, 'yyyy-MM-dd'))+" ")]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$parseDate(item.start_time, 'HH:mm') + ' - ' + _vm.$parseDate(item.end_time, 'HH:mm'))+" ")]}},{key:"item.status",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$t(("booking_status." + value)))+" ")]}},{key:"item.detail",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.showBooking(item)}}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)]}}],null,false,934404838)}):_vm._e()],1)],1)],1),_c('BookingDetailModal',{attrs:{"shown":_vm.bookingDetailModalShown,"booking":_vm.showingBooking},on:{"dismiss":function($event){_vm.bookingDetailModalShown = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }