<template>
  <BannerFrame :label="$t('app_drawer.book_facilities')">
    <v-container class="select-type">
      <v-row>
        <v-col v-for="itemType in itemTypes"
               :key="itemType.id"
               cols="12" md="6" lg="4">
          <v-hover v-slot="{hover}">
            <v-card @click="$router.push({name: 'search', params: {itemTypeId: itemType.id}})">
              <v-img :src="$getURL(itemType.banner)"
                     class="white--text align-end"
                     :gradient="hover ? null: 'to bottom, rgba(0,0,0,.01), rgba(0,0,0,.7)'"
                     height="300px">
                <v-card-title class="text-left text-break">{{ itemType.name }}</v-card-title>
              </v-img>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </BannerFrame>
</template>

<script>
import {ref, computed, onMounted} from '@vue/composition-api';
import BannerFrame from "@/components/BannerFrame";
import {ItemType} from "@/store/models";
import {PRIMARY_COLOR} from "@/plugins/vuetify";

export default {
  components: {BannerFrame},
  setup(props, {root}) {
    const itemTypes = computed(() => {
      return ItemType.query().where((i) => !i.deleted).where((i) => i.open_for_booking).orderBy('created_at').get();
    });

    const hoverGradient = function (a) {
      let h = PRIMARY_COLOR;
      let r = 0, g = 0, b = 0;

      // 3 digits
      if (h.length === 4) {
        r = parseInt("0x" + h[1] + h[1], 16);
        g = parseInt("0x" + h[2] + h[2], 16);
        b = parseInt("0x" + h[3] + h[3], 16);

        // 6 digits
      } else if (h.length === 7) {
        r = parseInt("0x" + h[1] + h[2], 16);
        g = parseInt("0x" + h[3] + h[4], 16);
        b = parseInt("0x" + h[5] + h[6], 16);
      }

      // console.log(`rgba(${r},${g},${b},${a})`)

      return `rgba(${r},${g},${b},${a})`
    }


    return {
      itemTypes,
      hoverGradient,
    }
  }
}
</script>
