var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"availability-grid"},[_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.cardTitle)+" ")]),_c('v-card-text',{staticStyle:{"height":"70vh"}},[(_vm.booking && _vm.filteredSessions.length)?_c('v-calendar',{attrs:{"type":"category","interval-height":"32","interval-width":"45","first-interval":_vm.firstInterval,"interval-count":_vm.intervalCount,"interval-minutes":"30","start":_vm.bookingDate,"end":_vm.bookingDate,"categories":_vm.categories,"events":_vm.filteredSessions,"event-color":_vm.getSessionColor,"event-overlap-threshold":1},on:{"click:event":_vm.sessionClicked},scopedSlots:_vm._u([{key:"category",fn:function(ref){
var category = ref.category;
return [_c('v-container',{staticClass:"text-center fixed-categories",attrs:{"fluid":""},on:{"click":function($event){return _vm.$emit('click:category', {event: $event, item: _vm.itemMap[category], item_id: category})}}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-icon',{attrs:{"color":"rgb(40, 97, 169)"}},[_vm._v("mdi-information-outline")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" "+_vm._s(_vm.itemMap[category].name)+" ")])])],1)],1)]}},{key:"event",fn:function(ref){
var event = ref.event;
return [_c('v-container',{class:['font-weight-bold', 'text-center', 'pa-0', 'fill-height', 'flex-column', {'required-approval': event.required_approval}, {'target-booking': event.booking && event.booking.id === _vm.booking.id}],attrs:{"fluid":""}},[_c('v-row',{staticClass:"flex-grow-1 d-flex",staticStyle:{"width":"100% !important"},attrs:{"no-gutters":""}},[(event.booking)?_c('v-col',{staticClass:"availability-grid-event-content fill-height",domProps:{"innerHTML":_vm._s(_vm.eventLabel(event))}}):_vm._e(),(event.pending_bookings && event.pending_bookings.length > 0)?_c('div',{staticClass:"availability-grid-pending-bookings"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){$event.stopPropagation();return _vm.showPendingBookings(event)}}},'v-icon',attrs,false),on),[_vm._v("mdi-account-clock-outline ")])]}}],null,true)},[_vm._v(" "+_vm._s(((_vm.$t('booking_status.PENDING_APPROVAL')) + ": " + (event.pending_bookings.length)))+" ")])],1):_vm._e()],1)],1)]}}],null,false,2113929732)}):_vm._e()],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary","disabled":!_vm.canSave},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('ok'))+" ")])],1),_c('BookingDetailModal',{attrs:{"shown":_vm.bookingDetailModalShown,"booking":_vm.showingBooking,"hide-edit":""},on:{"dismiss":function($event){_vm.bookingDetailModalShown = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }